import APIClient from './httpClient';

export const uploadTopicThumbnaileImage = async (thumbnailUploadData) => {
    const api = new APIClient();
    let bodyFormData = new FormData();
    bodyFormData.append('thumbnail', thumbnailUploadData.thumbnail);
    bodyFormData.append('user', thumbnailUploadData.user);
    bodyFormData.append('title', thumbnailUploadData.title);


    // update
    const endpoint = `/update/${thumbnailUploadData.id}/`;
    const { data } = await api.TopicMediaApi.put(endpoint, bodyFormData);
    return data;
}

export const topicCreateUpdate = async (topicData) => {

    const api = new APIClient();
    // console.log(topicData);
    // to post a file, use FormData. Postman confirms this. 
    let bodyFormData = new FormData();

    Object.entries(topicData).forEach(kvPair => {

        if (kvPair[1] !== null && kvPair[1] !== []) {
            bodyFormData.append(kvPair[0], kvPair[1])
        }
        return []
    })
    if (topicData.thumbnailFileData) {
        bodyFormData.append('thumbnail', topicData.thumbnailFileData);
    }
    if (topicData.id) {
        // update
        delete topicData.thumbnail
         /* DEBUG: when running update section and update topic full concurrently, you get slammed with this error 
        AttributeError: 'NoneType' object has no attribute 'user' 
        to correct it, make sure it is not running full as there would be conflicts
        */
        const endpoint = topicData.syllabus_sections?.length ? `/full/${topicData.id}/` : `/update/${topicData.id}/`;
        const { data } = await api.TopicApi.put(endpoint, topicData);
        return data;

    } else {
        // create
        const endpoint = topicData.syllabus_sections?.length !== 0 ? "/full/" : "/create/";
        const { data } = await api.TopicApi.post(endpoint, topicData);
        return data;

    }

}

export const sectionCreateUpdate = async (topicID, sectionData) => {
    /* create a new quiz
     */
    const api = new APIClient();
    if (Array.isArray(sectionData) || !(sectionData.id)) {
        const endpoint = `/${topicID}/sections/`;
        const { data } = await api.TopicApi.post(endpoint, sectionData);
        return data;
    } else {
        const endpoint = `/${topicID}/sections/${sectionData.id}/`;
        const { data } = await api.TopicApi.put(endpoint, sectionData);
        return data;

    }
}

export const getSyllabusSections = async (topicID) => {
    const api = new APIClient();
    const endpoint = `/${topicID}/sections/`;
    const { data } = await api.TopicApi.get(endpoint);
    return data;
}

export const sectionDelete = async (topicID, sectionID) => {
    /* create a new quiz
     */
    const api = new APIClient();
    const endpoint = `/${topicID}/sections/${sectionID}/`;
    const { data } = await api.TopicApi.delete(endpoint);
    return data;

}

export const getTopics = async () => {
    /* get the topics belonging to a user
     */
    const api = new APIClient();
    const endpoint = '/me/';

    const { data } = await api.TopicApi.get(endpoint);
    return data;
}

export const retrieveFullTopic = async (topicID) => {
    /* get the topics belonging to a user
     */
    const api = new APIClient();
    const endpoint = `/full/${topicID}/`;

    const { data } = await api.TopicApi.get(endpoint);
    return data;
}

export const createNewContent = async () => {
    /* get the topics belonging to a user
     */
    const api = new APIClient();
    const endpoint = '/content/';

    try {
        const { data } = await api.TopicApi.get(endpoint);
        return data;

    } catch (error) {
        return {}
    }
}



