import React from 'react';
import styled from 'styled-components';
import NotesIcon from '../../assets/icons/notes-wine.svg';
import { PassButton } from "../uiComponents/button";
import SnackBar from 'my-react-snackbar';
import { convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { Input } from '../input';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { noteCreateUpdate } from '../../utils/api/noteApi';
import { calculateDurationForNote } from '../../utils/utilFnx';
import { appendItemToLocal } from '../../utils/functions/storage';


class NoteModal extends React.Component {

    constructor(props) {
        super();
        this.state = {
            id: null,
            title: "",
            note: null,
            snackMessage: { type: null, message: null },
        }
    }

    componentDidMount = () => {
        this.setState({
            ...this.state, ...this.props.data
        })

    }


    handleSubmitNote = async (e) => {
        const currentContentPlain = convertFromRaw(this.state.note)?.getPlainText('');

        await this.setState({
            duration: calculateDurationForNote(currentContentPlain),
        })
        this.props.onSavingState("Loading...")

        noteCreateUpdate(this.state).then(data => {
            appendItemToLocal("unconfirmedNotes", data.id)
            this.setState({
                ...this.state, ...data
            })
            this.props.onSavingState("Done")

        }).catch(err => {
            console.log(err.response, calculateDurationForNote(currentContentPlain));
            this.props.onSavingState("Error")
        }).finally(() => {
            // NOTE: this makes sync after it is stored in the db. 
            // TODO: Add to the "then". it should be fine, i guess
            this.props.onSubmitNote(this.props.index, { content: this.state.id, contentData: this.state, modal: false })
        })
        // this is an async update of the data while it is storing in db
        this.props.onSubmitNote(this.props.index, { content: this.state.id, contentData: this.state, modal: false })
    }


    onEditorStateChange = (note) => {
        this.setState({
            note: note,
        });
    };
    onTextChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };



    render() {

        return (
            <ModalContainter
                onClick={e => { e.stopPropagation() }}
                width="100%"
                bottom="0"
                right="0"
                top="0"
                left="0"
                radius="0"
                display={this.props.show ? "block" : "none"}

            >
                <SnackBar
                    open={this.state.snackMessage.message}
                    message={this.state.snackMessage.message}
                    type={this.state.snackMessage.type}
                    position="bottom-right"
                    timeout={2000}
                />
                <div className="modalBody">
                    <div className="container">
                        <div className="leftPane">
                            <img src={NotesIcon} alt="Note icon" />
                        </div>
                        <div className="rightPane">
                            <div className="formTop">
                                <h3 className="title">Notes</h3>
                                <p>
                                    Type up notes using the rich text editor.
                                </p>
                            </div>

                            <Input
                                placeholder="Title of Note"
                                name="title"
                                value={this.state.title}
                                onChange={this.onTextChange}
                            />

                            {
                                // NOTE: the condition below is necessary since the `Editor` component
                                // doesnt refresh even after the initial state change
                                (((this.props.data.note) && this.state.note) || !(this.props.data.note)) && <Editor
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onContentStateChange={this.onEditorStateChange}
                                    defaultContentState={this.state.note}
                                />}

                            <div
                                className="buttonContainer">
                                <PassButton
                                    color="#535353"
                                    background="#ffffff"
                                    border="1px solid #535353"
                                    onClick={this.props.handleClose}
                                >
                                    {this.props.primaryButton || 'Cancel'}
                                </PassButton>

                                <PassButton
                                    disabled={!(this.state.title)}
                                    onClick={this.handleSubmitNote}
                                    color={(this.state.title) ? "#fff" : "#535353"}
                                    background={(this.state.title) ? "#535353" : "#e8e8e8"}>
                                    Done
                                </PassButton>

                            </div>
                        </div>
                    </div>

                </div>

            </ModalContainter>
        );
    }

}


export default NoteModal





const ModalContainter = styled.div`
    width: ${({ width }) => width || "100%"};
    position:fixed;
    background: ${({ background }) => background || "rgba(0, 0, 0, 0.6)"};
    color: ${({ color }) => color || "#ffffff"};
    border-radius: ${({ radius }) => radius || "0px"};
    height: auto;
    top: ${({ top }) => top};
    left: ${({ left }) => left};
    bottom: ${({ bottom }) => bottom};
    right: ${({ right }) => right};
    transform: ${({ transform }) => transform};
    z-index: 1;
    display: ${({ display }) => display} !important; // make modal work in responsivenss


    .modalBody{

        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        margin: 0 50px;

        .container {
            display: flex;
            width: 80%;
            height: 70%;

        }

        .leftPane{
            background-color: #fff;
            width: 50%;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            padding: 20px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: black;

            img {
                height: 175px;
            }
        }

        .rightPane{
            background-color: #F7F7F7;
            width: 100%;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            box-sizing: border-box;
            padding: 0px 8%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            overflow: hidden scroll;

            .wrapperClassName{
                color: #030508;
                border: #0A111F 1px solid;
                border-radius: 5px;
                padding: 5px;
                min-width: 100%;
            }

            .editorClassName{
                
                background-color: #ebf5ff !important;
                padding: 5px 15px;
            }

            .rdw-editor-main{
                height: auto;
            }


            .buttonContainer{
                margin: 20px;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;  
            }

            .formTop {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                margin-bottom: 30px;

                input {
                    margin-top: 20px;
                    border-bottom: 1px solid  #A93B3F;
                    color: #A93B3F;
                    margin-bottom: 10px;
                    width: 100%;
                }

                input::placeholder{
                    color: #A93B3F;
                }
            }

            .title {
                color: #535353;
                font-size: 32px;
                margin-bottom: 0;
            }
            p{
                color: #767676;
                width: 90%;
                margin-right: 10%;
                font-size: 15px;
                line-height: 1.6;
            }

            a{
                text-decoration: none;
                background-color: #ebf5ff;
                border: 1px solid #0366d650;
                color: #0366d6;

            }
        }
        

    }
    @media only screen and (max-width: 768px){
        background-color: white !important;
        margin: 0 auto !important;
        div{
            background-color: white !important;
        }

        .modalBody{
            margin: 0 10px;
            .container{
                display: block;
                width: 100%;
                height: 90%;
                margin: 0px auto;
                text-align: -webkit-center;
                overflow: auto;
            }

            .leftPane{
                width: 90%;
                border-bottom-left-radius: 0;
                border-top-right-radius: 15px;

                img {
                    height: 125px;
                }
            }
            .rightPane{
                border-top-right-radius: 0;
                border-bottom-left-radius: 15px;
                width: 90%;
            }
        }
    }

    
`