
import React from 'react';
import { HighlightedText } from '../highlightedText';
import { Card } from "../card";
import { Button } from '../uiComponents/button';
import AddItemIcon from '../../assets/icons/arrow_right_red.svg'
import DeleteIcon from '../../assets/icons/trash-can_white.svg'
import UnPublishedIcon from '../../assets/icons/minus_red.svg'
import PublishedIcon from '../../assets/icons/checkmark_green.svg'
import SaveIcon from '../../assets/icons/floppy-disk_green.svg'
import EditIcon from '../../assets/icons/pencil.svg'
import { Collapse } from 'react-collapse';
import { Input } from '../input';
import SyllabusContent from './syllabusContent';
import BarLoader from "react-spinners/BarLoader";
import { css } from "@emotion/react";


class SyllabusSection extends React.Component {

    constructor(props) {
        super();
        this.state = {
            collapse: true,
            content_type: "note",
        }
    }


    onAddSyllabusContent = (e) => {

        // when someone clicks on th Add module button, an empty map is added to the 
        // `syllabusSection` array in state. and then edit=True property is set for that item.
        let allSyllabusContent = [
            ...this.props.data.syllabus_contents,
            {
                content_type: this.state.content_type,
                modal: true,
                order: this.props.data.syllabus_contents.length,
                content: {},
            }
        ]

        this.props.onEdit(this.props.index, { syllabus_contents: allSyllabusContent })
    }

    onEditSyllabusContent = (id, data, command) => {
        if (command === "save-all") {
            this.props.onEdit(this.props.index, data, command)
        }
        // console.log(data);
        let allSyllabusContent = this.props.data.syllabus_contents
        let syllabusContent = allSyllabusContent[id]
        if (command === "delete") {
            allSyllabusContent.splice(id, 1);

        } else {

            syllabusContent = { ...syllabusContent, ...data }
            allSyllabusContent[id] = syllabusContent
        }
        // updating the syllabus content on the bigger screen 
        // console.log(this.props.data.syllabus_contents);

        this.props.onEdit(this.props.index, { syllabus_contents: allSyllabusContent })

    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }


    render = () => {
        // console.log(this.props.data);
        return (
            <Card border="2px solid grey" radius="0.2em" margin="1em">
                <Card display="flex" width="100%" space="space-between" radius="0.5em">
                    {this.props.data.edit ?
                        <Card display="flex" width="70%" >

                            <Input
                                placeholder="Title"
                                name="title"
                                width="50%"
                                margin="5px 10px"
                                value={this.props.data.title || ""}
                                onChange={(e) => this.props.onEdit(this.props.index, { [e.target.name]: e.target.value })} />
                            <Card padding="0em" width="20%">
                                <label>
                                    <select
                                        name="order"
                                        style={{ margin: "10px" }}
                                        value={this.props.data.order}
                                        onChange={(e) => this.props.onEdit(this.props.index, { [e.target.name]: e.target.value })}>
                                        <option value={this.props.length - 1}>Order(last)</option>
                                        {
                                            [...Array(this.props.length).keys()].map((orderNumber) => {
                                                return <option key={orderNumber} value={orderNumber}>{orderNumber + 1}</option>
                                            })
                                        }
                                    </select>
                                </label>
                            </Card>
                        </Card>
                        : <Button
                            onClick={() => this.setState({ collapse: !this.state.collapse })}
                            background="transparent"
                            padding="0" margin="0 0"
                            radius="1em"
                            display="flex"
                            space="space-between"
                            width="70%"
                        >

                            <HighlightedText margin="1em" size="16px" weight="" lineHeight="1.5">
                                {this.props.data.title || "No title"}
                            </HighlightedText>
                        </Button>
                    }
                    {this.props.data.loading ?

                        <BarLoader css={LoadingAnimationStyle} color="#923d41" />
                        :
                        this.props.data.edit ?
                            <Card background="rgba(240, 240, 214, 0.5)" display="flex" radius="0.5em" width="35%" space="space-between">
                                <Button
                                    background="rgba(232, 232, 232, 1)"
                                    style={{ cursor: this.props.data.id ? "pointer" : "no-drop" }}
                                    color="rgba(46, 49, 49, 1)"
                                    padding="8px 15px" margin="10px"
                                    onClick={() => {
                                        if (this.props.data.id) {
                                            this.props.onEdit(this.props.index, { published: !this.props.data.published }, "push")
                                        }
                                    }}
                                >
                                    {this.props.data.published ? "Published" : "Publish"} <img src={
                                        this.props.data.published
                                            ? PublishedIcon
                                            : UnPublishedIcon
                                    } alt="unpublished/published" width="15px" height="15px"
                                    />
                                </Button>
                                <Button padding="8px 15px" margin="10px"
                                    onClick={() => this.props.onEdit(this.props.index, {}, "delete")}
                                >
                                    Delete <img src={DeleteIcon} width="15px" height="15px" alt="delete icon" />
                                </Button>

                                <Button
                                    padding="8px 15px"
                                    margin="10px"
                                    color="rgba(30, 130, 76, 1)"
                                    background="rgba(38, 166, 91, 0.3)"
                                    onClick={() =>
                                        this.props.onEdit(this.props.index, { edit: !this.props.data.edit }, "save")}>

                                    Save <img src={SaveIcon} width="15px" height="15px" alt="saveIcon" />
                                </Button>


                            </Card> :

                            <span>
                                <Button
                                    background="rgba(232, 232, 232, 0.5)"
                                    style={{ cursor: this.props.data.id ? "pointer" : "no-drop" }}
                                    color="rgba(46, 49, 49, 1)"
                                    padding="8px 15px" margin="10px"
                                    onClick={() => {
                                        if (this.props.data.id) {
                                            this.props.onEdit(this.props.index, { published: !this.props.data.published }, "push")
                                        }
                                    }}
                                    radius="30%"
                                >
                                    <img src={
                                        this.props.data.published
                                            ? PublishedIcon
                                            : UnPublishedIcon
                                    } alt="unpublished/published" width="15px" height="15px"
                                    />
                                </Button>
                                <Button
                                    background="rgba(232, 232, 232, 1)"
                                    color="rgba(46, 49, 49, 1)"
                                    padding="8px 15px" margin="10px"
                                    onClick={() => this.props.onEdit(this.props.index, { edit: !this.props.data.edit })}>
                                    Edit <img src={EditIcon} width="12px" height="12px" alt="edit icon" />
                                </Button>
                            </span>
                    }
                </Card>
                <Collapse isOpened={this.state.collapse}>
                    <ul>

                        {this.props.data.syllabus_contents.map((syllabusContent, i) =>
                            <SyllabusContent
                                key={i}
                                index={i}
                                data={syllabusContent}
                                onEdit={this.onEditSyllabusContent}
                                length={this.props.data.syllabus_contents.length}
                                edit={this.props.data.edit}
                            />)
                        }

                        <li>
                            <Card display="flex" color="#923D41" border="2px solid #923D41" padding="0.5em" margin="1em 1em 1em 0" space="space-between">
                                <img src={AddItemIcon} width="30px" alt="add item" />
                                <HighlightedText size="16px" weight="500" color="#923D41" lineHeight="1.5">

                                </HighlightedText>
                                <Button
                                    size="16px"
                                    weight="500"
                                    color="#923D41"
                                    lineHeight="1.5"
                                    background="rgba(38,166,91,0.3)"
                                    padding="5px 10px"
                                    radius="5px"
                                    margin="0 0 0 1em"
                                    onClick={this.onAddSyllabusContent}
                                >
                                    Add a new material
                                </Button>
                                <HighlightedText size="13px" weight="600" color="grey" lineHeight="1.5" margin="0 0.5em">
                                    <Card padding="0em">
                                        <label>
                                            Module type:
                                            <select name="content_type" value={this.state.content_type} onChange={this.handleChange}>
                                                <option value="note">Note</option>
                                                <option value="video">Video</option>
                                                <option value="quiz">Quiz/Assessment</option>
                                            </select>
                                        </label>
                                    </Card>
                                </HighlightedText>


                            </Card>

                        </li>

                    </ul>
                </Collapse>

            </Card>
        )

    }
}

export default SyllabusSection;


const LoadingAnimationStyle = css`
  align-self: center;
  margin: 0 auto;

`;
