import React from 'react';
import {
    Area,
    AreaChart, ResponsiveContainer,
    XAxis, YAxis, Tooltip, Legend
} from 'recharts';

import { numberingShortnerReadable } from '../../utils/utilFnx';


class AnalyticsGraph extends React.Component {

    makeDummyDataPoint = (dataSeriesMeta) => {
        let dummy = {
            [dataSeriesMeta.x_axis.data]: "",
        }

        dataSeriesMeta.y_axis.forEach(y => {
            dummy[y.data]= 0
        });

        return [dummy]


    }

    render = () => {
        const graphColors = this.props.graphColors
        const dataSeries = this.props.dataSeries
        const dataSeriesMeta = this.props.dataSeriesMeta
        return <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={dataSeries.length ? dataSeries : this.makeDummyDataPoint(dataSeriesMeta)}
                margin={{ top: 10, right: 5, left: 0, bottom: 0 }}>
                <defs>
                    {
                        dataSeriesMeta.y_axis.map((y_axis, y_i) =>
                            <linearGradient key={y_i} id={`video${y_i}`} x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor={graphColors[y_i % graphColors.length]} stopOpacity={0.8} />
                                <stop offset="95%" stopColor={graphColors[y_i % graphColors.length]} stopOpacity={0} />
                            </linearGradient>)
                    }
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                    </linearGradient>
                </defs>
                <XAxis dataKey={dataSeriesMeta.x_axis} interval={2} padding={{ right: 20 }} />
                <YAxis interval={3} tickFormatter={numberingShortnerReadable} />
                <Tooltip />
                <Legend verticalAlign="bottom" height={36} />
                {
                    dataSeriesMeta.y_axis.map((y_axis, y_i) =>
                        <Area
                            key={y_i}
                            type="monotone"
                            name={y_axis.presentation}
                            dataKey={y_axis.data}
                            stroke={graphColors[y_i % graphColors.length]}
                            fillOpacity={1} fill={`url(#video${y_i})`}
                        />
                    )
                }

            </AreaChart>
        </ResponsiveContainer>

    }
}

export default AnalyticsGraph