import React from 'react';
import styled from 'styled-components';
import ErrorPicture from '../../assets/images/Group49.svg';
import { PassButton } from "../uiComponents/button";


class AppModal extends React.Component {




    render() {
        const showHideClassName = this.props.show ? "modal display-block" : "modal display-none";

        // console.log(this.props);
        return (
            <div className={showHideClassName} onClick={this.props.handleClose}>
                <ModalContainter
                    onClick={e => { e.stopPropagation() }}
                    width="100%"
                    bottom="0"
                    right="0"
                    top="0"
                    left="0"
                    radius="0"
                >

                    <div className="modalBody">
                        <div className="container">
                            <div className="leftPane">


                                <img src={this.props.image ?? ErrorPicture} alt="ResetPass asset" />

                            </div>
                            <div className="rightPane">
                                <div className="formTop">
                                    <h3 className="title">{this.props.header || "Oops!"}</h3>
                                    <p>{
                                        this.props.message
                                        || "Something went wrong. Contact IT for  assistance."}
                                    </p>
                                </div>
                                {this.props.body}
                                <div
                                    className="buttonContainer">
                                    <PassButton
                                        color="#535353"
                                        background="#ffffff"
                                        border="1px solid #535353"
                                        onClick={this.props.handleClose}
                                    >
                                        {this.props.secondaryButton || 'Cancel'}
                                    </PassButton>

                                    <PassButton
                                        onClick={this.props.primary}
                                        color="#fff"
                                        background="#535353">
                                        {this.props.primaryButton || 'Done'}
                                    </PassButton>

                                </div>
                            </div>
                        </div>

                    </div>

                </ModalContainter>
            </div>
        );
    }
}


export default AppModal





const ModalContainter = styled.div`
    width: ${({ width }) => width || "100%"};
    position:fixed;
    background: ${({ background }) => background || "rgba(0, 0, 0, 0.6)"};
    color: ${({ color }) => color || "#ffffff"};
    border-radius: ${({ radius }) => radius || "0px"};
    height: auto;
    top: ${({ top }) => top};
    left: ${({ left }) => left};
    bottom: ${({ bottom }) => bottom};
    right: ${({ right }) => right};
    transform: ${({ transform }) => transform};
    z-index: 1;

    .modalBody{

        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        margin: 0 50px;

        .container {
            display: flex;
            width: 80%;
            height: 70%;

        }

        .leftPane{
            background-color: #fff;
            width: 50%;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            padding: 20px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: black;

            img {
                height: 175px;
            }
        }

        .rightPane{
            background-color: #F7F7F7;
            width: 100%;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            box-sizing: border-box;
            padding: 0px 8%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            overflow: scroll;


            .buttonContainer{
                margin: 20px;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;  
            }

            .formTop {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                margin-bottom: 30px;

                input {
                    margin-top: 20px;
                    border-bottom: 1px solid  #A93B3F;
                    color: #A93B3F;
                    margin-bottom: 10px;
                    width: 100%;
                }

                input::placeholder{
                    color: #A93B3F;
                }
            }

            .title {
                color: #535353;
                font-size: 32px;
                margin-bottom: 0;
            }
            p{
                color: #767676;
                width: 90%;
                margin-right: 10%;
                font-size: 15px;
                line-height: 1.6;
            }
        }

    }
    
    @media only screen and (max-width: 768px){
        .modalBody{
            margin: 0 10px;
            .container{
                display: block;
                width: 100%;
                height: 90%;
                margin: 0px auto;
                text-align: -webkit-center;
                overflow: auto;
            }

            .leftPane{
                width: 90%;
                border-bottom-left-radius: 0;
                border-top-right-radius: 15px;

                img {
                    height: 125px;
                }
            }
            .rightPane{
                border-top-right-radius: 0;
                border-bottom-left-radius: 15px;
                width: 90%;
            }
        }
    }
    
`