import styled from "styled-components";

export const Input = styled.input`
    border: ${({border}) => border || "1px solid black"};
    border-radius: ${({radius}) => radius || "3px"};
    display: ${({ display }) => display || "block"};
    padding: ${({ padding }) => padding || "10px 20px"};
    margin: ${({ margin }) => margin || "25px auto"};
    width: ${({ width }) => width || "80%"};
    height: ${({ height }) => height || "1.5em"};
    

    text-align: ${({ textAlign }) => textAlign || "left"};;
    font-size: ${({ size }) => size || "14px"};
    text-transform: ${({ textTransform }) => textTransform || "none"};
    font-weight: ${({ weight }) => weight || "bold"};
    letter-spacing: 0;
    color: ${({ color }) => color || "#000"};

    &:focus {
        outline: none !important;
        border: ${({borderFocus}) => borderFocus || "2px solid #923D41"};
    }
    
`

export const TextArea = styled.textarea`

    border: 1px solid black;
    border-radius: 5px;
    display: block;
    padding: 8px 15px;
    margin: ${({ margin }) => margin || "15px 2px"};
    width: -moz-available;
    width: ${({ width }) => width || "-webkit-fill-available"};
    text-align: left;
    font-size: 15px;
    font-weight: ${({ weight }) => weight || "bold"};
    letter-spacing: 0;
    color: #923D41;
    resize: ${({ resize }) => resize};
    display: ${({ display }) => display};

    &:focus {
        outline: none !important;
        border:2px solid #923D41;
        box-shadow: 0px 12px 30px #923D411A;
    }

    input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #923D41;
        font-weight: normal;
        opacity: 1; /* Firefox */
      }
`