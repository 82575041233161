import axios from 'axios';

/* 
if going to production, change the `url` in APIClient to url for production in .env
*/

class APIClient {

  url = process.env.REACT_APP_SKIDEO_BACKEND;

  accountApi = axios.create({
    baseURL: `${this.url}/account/`,
  });


  accountAuthApi = axios.create({
    baseURL: `${this.url}/account/`,
    headers: {
      Authorization: Boolean(localStorage.getItem("a_bearer")) ? `Token ${JSON.parse(localStorage.getItem("a_bearer"))}` : null
    }
  });

  AnalyticsApi = axios.create({
    baseURL: `${this.url}/analytics/`,
    headers: {
      Authorization: Boolean(localStorage.getItem("a_bearer")) ? `Token ${JSON.parse(localStorage.getItem("a_bearer"))}` : null
    }
  });
  DialogueApi = axios.create({
    baseURL: `${this.url}/dialogue/`,
    headers: {
      Authorization: Boolean(localStorage.getItem("a_bearer")) ? `Token ${JSON.parse(localStorage.getItem("a_bearer"))}` : null
    }

  });

  IxamApi = axios.create({
    baseURL: `${this.url}/ixam/`,
    headers: {
      Authorization: Boolean(localStorage.getItem("a_bearer")) ? `Token ${JSON.parse(localStorage.getItem("a_bearer"))}` : null
    }

  });

  // NOTE API
  NoteApi = axios.create({
    baseURL: `${this.url}/note/`,
    headers: {
      Authorization: Boolean(localStorage.getItem("a_bearer")) ? `Token ${JSON.parse(localStorage.getItem("a_bearer"))}` : null,
    }
  });

  QuizApi = axios.create({
    baseURL: `${this.url}/quiz/`,
    headers: {
      Authorization: Boolean(localStorage.getItem("a_bearer")) ? `Token ${JSON.parse(localStorage.getItem("a_bearer"))}` : null
    }

  });

  // TOPIC API
  TopicApi = axios.create({
    baseURL: `${this.url}/topic/`,
    headers: {
      Authorization: Boolean(localStorage.getItem("a_bearer")) ? `Token ${JSON.parse(localStorage.getItem("a_bearer"))}` : null,
    }
  });
  
  // this was dedicated to just files in using the topic api. 
  // Using form for the main api endpoint variable above can
  // be tricky because of embedded form data inside. such as syllabus_sections, etc
  TopicMediaApi = axios.create({
    baseURL: `${this.url}/topic/`,
    headers: {
      Authorization: Boolean(localStorage.getItem("a_bearer")) ? `Token ${JSON.parse(localStorage.getItem("a_bearer"))}` : null,
      "content-type": 'multipart/form-data',
    }
  });

  // VIDEO API
  VideoApi = axios.create({
    baseURL: `${this.url}/video/`,
    headers: {
      Authorization: Boolean(localStorage.getItem("a_bearer")) ? `Token ${JSON.parse(localStorage.getItem("a_bearer"))}` : null,
    }
  });



}

export default APIClient;
