import APIClient from './httpClient';

export const getQuizzes = async () => {
    /* get the quizzes belong to the user making the request
     */
    const api = new APIClient();
    const endpoint = '/me/';

    const { data } = await api.QuizApi.get(endpoint);
    return data;

}

export const deleteQuiz = async (id) => {
    /* delete a quiz
     */
    const api = new APIClient();
    const endpoint = `/${id}/`;

    try {
        const { data } = await api.QuizApi.delete(endpoint);
        return data;

    } catch (error) {
        return {}
    }
}


export const getOnlyQuizQuestions = async (id) => {
    /* get only questions for a particular quiz
     */
    const api = new APIClient();
    const endpoint = `/${id}/instructor/questions/`;

    const { data } = await api.QuizApi.get(endpoint);
    return data;

}

export const getQuizAndQuestions = async (id) => {
    /* get only questions for a particular quiz
     */
    const api = new APIClient();
    const endpoint = `/${id}/`;


    const { data } = await api.QuizApi.get(endpoint);
    return data;


}


export const quizCreateUpdate = async (quizData) =>{
    /* create a new quiz
     */
    const api = new APIClient();
    // console.log(quizData);

    if (quizData.id) {
        // update
        const endpoint = `/${quizData.id}/`;
        const { data } = await api.QuizApi.put(endpoint, quizData);
        return data;

    } else {
        // create
        const endpoint = "/";
        const { data } = await api.QuizApi.post(endpoint, quizData);
        return data;

    }
}
