import React from 'react';
import { Card } from '../../components/card';
import { InputLabel } from '../../components/uiComponents/InputLabel';
import { Input } from "../../components/input";
import { Button } from '../../components/uiComponents/button';

class AnswerCard extends React.Component {
    render = () => {
        return (
            <Card
                alignItems="center"
                display="flex">
                <Input
                    name="answer"
                    margin="2em"
                    value={this.props.data.answer || ""}
                    placeholder="Possible answer"
                    onChange={(e) => this.props.onEdit(this.props.index, { [e.target.name]: e.target.value })}
                />

                {this.props.genre === "multiple-choice" &&
                    <InputLabel width="30%">
                        <input
                            name="correct"
                            checked={this.props.data.correct || false}
                            type="checkbox"
                            onChange={(e) => this.props.onEdit(this.props.index, { [e.target.name]: e.target.checked })}
                        />
                            Correct Answer
                    </InputLabel>
                }
                <Button
                    padding="5px 10px"
                    background="rgba(246, 36, 89, 0.2)"
                    color="rgba(207, 0, 15, 1)"
                    onClick={(e) => this.props.onEdit(this.props.index, {}, "delete")}
                >
                    Delete
                </Button>


            </Card>
        )
    }
}

export default AnswerCard