import React from 'react';
import Login from '../pages/unauthorized/login'
import SignUp from '../pages/unauthorized/signup'
import ForgotPassword from '../pages/unauthorized/forgotPassword'
import ResetPassword from '../pages/unauthorized/resetPassword'
import { Switch, Route, Redirect } from 'react-router-dom';
import ActivateAccount from '../pages/unauthorized/activate'



class UnAuthorized extends React.Component {

    constructor(props) {
        super();
        this.state = {
            showBroadcast: true
        }
    }

    render() {
        return (
            <div>
                {/* TODO: take out if a better way of broadcasting new is found */}
                {/* {this.state.showBroadcast &&
                    <Card
                        margin="1em"
                        padding="1em"
                        background="#fffadb"
                        display="flex"
                        space="space-between"
                        style={{ position: "relative", zIndex: 1 }}
                    >

                        <HighlightedText weight="normal" size="15px">
                            Welcome to the <b>Beta testing of Skideo</b>.
                            Please note that a stable server is currently being purchased. As such, you may experience slow loading and refreshing. Please bear with us.
                            <br />
                            <br />
                            Also, please refrain from putting legitimate data in the fields here. Random data can do for now.
                            You would be alerted when this is solved. Thank you.
                        </HighlightedText>

                        <Button
                            padding="10px"
                            height="min-content"
                            onClick={() => {
                                this.setState({
                                    showBroadcast: false
                                })
                            }}
                        >
                            Close
                        </Button>
                    </Card>} */}
                <Switch>
                            <Route exact path={`${this.props.match.path}/register`} component={SignUp} />
                            <Route exact path={`${this.props.match.path}/activate`} component={ActivateAccount} />
                            <Route exact path={`${this.props.match.path}/login`} component={Login} />
                            <Route exact path={`${this.props.match.path}/forgot`} component={ForgotPassword} />
                            <Route exact path={`${this.props.match.path}/reset/:resetID`} component={ResetPassword} />
                            <Redirect to="/auth/login" />
                        </Switch>
            </div>
        )
    }
}

export default UnAuthorized;
