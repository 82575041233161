
import React from 'react';
import styled from 'styled-components';
import { HighlightedText } from '../../components/highlightedText';
import { Card } from "../../components/card";
import { AshMidTitle } from "../../components/uiComponents/text";
import { Button } from '../../components/uiComponents/button';
import AddSectionIcon from '../../assets/icons/plus_rounded_wine.svg'
import { Input, TextArea } from "../../components/input";
import { getFirstName, getLastName, getUserId } from '../../utils/storage/auth';
import ExtraInfoTab from '../../components/topic/extraInfoCard';
import SyllabusSection from '../../components/topic/syllabusSection';
import UnPublishedIcon from '../../assets/icons/minus_red.svg'
import PublishedIcon from '../../assets/icons/checkmark_green.svg'
import SavedIcon from '../../assets/icons/checkmark-settings_green.svg'
import {
    getSyllabusSections,
    retrieveFullTopic,
    sectionCreateUpdate,
    sectionDelete,
    topicCreateUpdate,
    uploadTopicThumbnaileImage
} from '../../utils/api/topicApi';
import { createBrowserHistory } from 'history'
import AppModal from "../../components/uiComponents/modal";
import AddNewTopicIllustration from "../../assets/illustrations/add_new.svg";
import SnackBar from 'my-react-snackbar';
import BeatLoader from "react-spinners/BeatLoader";
import { convertDurationStringToSeconds, durationReadableFromNumber, readableServerError } from '../../utils/utilFnx';
import { Prompt } from 'react-router';

class Topic extends React.Component {

    constructor(props) {
        super();
        this.state = {
            topic: {
                payment_plan: "per view",
                labels: [],
                published: false,
                level: "Beginner",
                user: getUserId()
            }, // defaults. very important until further notice
            learning_goals: [],
            label: "",
            syllabus_sections: [],// [{edit:true, published: true}], //defaults
            saveChanges: false,
            newTopicModal: false,
            loading: false,
            snackMessage: { type: null, message: null },
        }

        this.onRefreshPrompt = e => {
            // the method that will be used for both add and remove event
            e.preventDefault();
            e.returnValue = '';
        }
    }

    componentDidMount = () => {
        // prompt the user they'll loose all saved work if refreshed

        // TODO: uncomment this before launch
        if (!process.env.REACT_APP_DEBUG) {
            window.addEventListener("beforeunload", this.onRefreshPrompt);
        }

        if (this.props.match.params.topicID) {
            if (this.props.location.state != null) {
                // console.log(this.props.location.state);
                this.setState({
                    topic: { ...this.props.location.state, user: getUserId() },
                    learning_goals: this.props.location.state.learning_goals,
                    loading: true,
                })
                getSyllabusSections(this.props.match.params.topicID).then((data) => {
                    this.setState({
                        syllabus_sections: data,
                        loading: false,
                    })
                }).catch((err) => {
                    console.log(err.response);
                    this.setState({
                        snackMessage: { type: "error", message: readableServerError(err) },
                        loading: false,
                    })
                })
                // without the 2 lines below, the state still 
                // refers to the old state of the application even after refresh
                const history = createBrowserHistory();
                history.replace();

            }
            else {
                this.setState({
                    loading: true
                })
                retrieveFullTopic(this.props.match.params.topicID).then(data => {
                    // console.log(data);
                    this.setState({
                        topic: { ...data, user: getUserId() },
                        learning_goals: data.learning_goals,
                        syllabus_sections: data.syllabus_sections,
                        loading: false,
                    })
                })

            }
        } else {
            // create a new topic but with name first
            this.setState({
                newTopicModal: true
            })
        }


    }

    componentWillUnmount = () => {
        if (!process.env.REACT_APP_DEBUG) {
            window.removeEventListener("beforeunload", this.onRefreshPrompt)
        }
    }


    componentDidUpdate(prevProps, prevState) {
        if (this.prop === prevProps || this.state === prevState) {
            // for resetting the course if someone show click on a create new course
            // TODO: expound on this to make it possible for it to load topic details when one with ID comes into play 
            this.setState({
                topic: {
                    payment_plan: "per view",
                    labels: [],
                    published: false,
                    level: "Beginner",
                    user: getUserId()
                },
                syllabus_sections: [],
                learning_goals: [],
                saveChanges: false,
                newTopicModal: true,
            })
        }
    }


    onTopicChange = (e) => {
        this.setState({
            topic: { ...this.state.topic, [e.target.name]: e.target.value },
            saveChanges: true,
        });
        // console.log(this.state.topic)
    }

    onAddDeleteLabel = (e, command) => {
        // TODO: merge this function into `onTopicChange`
        if (command === "delete") {
            const updatedLabels = this.state.topic.labels.filter(item => !(item === e))

            this.setState({
                topic: { ...this.state.topic, labels: updatedLabels }, saveChanges: true,
            })

        } else {
            // add a new key value to the end
            this.setState({
                // NOTE: the variable for storing labels interrim is called 
                label: "",
                topic: { ...this.state.topic, labels: [...this.state.topic.labels, this.state.label], },
                saveChanges: true,
            })
        }


    }
    onVariableChange = (e) => {
        // track variable changes that are not conventional
        this.setState({
            [e.target.name]: e.target.value, saveChanges: true,
        });
        // console.log(this.state.label);
    }


    handleImages = (e) => {
        // console.log(e.target.files[0]);
        this.setState({
            thumbnail: URL.createObjectURL(e.target.files[0]),
            thumbnailFileData: e.target.files[0],
            saveChanges: true,
        })
    }



    onChangeLearningGoal = (e) => {

        let learning_goals = this.state.learning_goals
        learning_goals[e.target.name] = e.target.value

        this.setState({
            learning_goals: learning_goals,
            saveChanges: true,
        })
        // console.log(this.state.learning_goals);
    }


    onAddModule = (e) => {

        // when someone clicks on th Add module button, an empty map is added to the 
        // `syllabusSection` array in state. and then edit=True property is set for that item.
        this.setState({
            syllabus_sections: [
                ...this.state.syllabus_sections,
                {
                    edit: true,
                    published: false,
                    order: this.state.syllabus_sections.length,
                    syllabus_contents: []
                }]
        })

    }

    onEditSyllabusSection = (id, data, command) => {
        if (command === "save-all") {
            return this.onSaveTopic()
        }
        let allSyllabusSection = this.state.syllabus_sections
        let syllabusSection = allSyllabusSection[id]

        if (command === "delete") {
            allSyllabusSection.splice(id, 1);
            if (syllabusSection.id) {
                sectionDelete(this.state.topic.id, syllabusSection.id).then(d=>{
                    this.setState({
                        snackMessage: { type: "info", message: "Delete success" },
                    })
                })
                this.updateTopicWithDuration(allSyllabusSection)
            }

        } else {

            syllabusSection = {
                ...syllabusSection,
                ...data,
                loading: command === "save" || command === "push"
            }
            allSyllabusSection[id] = syllabusSection
        }
        this.setState({
            syllabus_sections: allSyllabusSection,
            saveChanges: true,
        })
        if (command === "save") {
            // console.log(syllabusSection.syllabus_contents);
            if (this.state.topic.id) { // if the topic already on the db
                // save the syllabus section
                this.updateTopicWithDuration(allSyllabusSection)
                // NOTE: this is storing data for a single section
                sectionCreateUpdate(this.state.topic.id, syllabusSection).then((responseData) => {
                    // console.log(responseData);
                    responseData.syllabus_contents = this.updateSyllabusContents(syllabusSection.syllabus_contents, responseData.syllabus_contents)
                    this.onEditSyllabusSection(id, responseData) // this takes care of `loading: false`
                }).catch((error) => {
                    // console.log(readableServerError(error), error.response);
                    this.onEditSyllabusSection(id, data)
                    throw error
                })


            }

            else {
                // save the topic
                this.onSaveTopic(id)
                // NOTE: after it is saved, clear from the draft cache
            }
        } else if (command === "push") {
            // when wanting to push one thing.
            if (syllabusSection.id) {
                sectionCreateUpdate(this.state.topic.id, syllabusSection).then((d) => {

                    this.onEditSyllabusSection(id, d)
                })
            } else {
                this.onEditSyllabusSection(id, syllabusSection, "save")
            }
        }
    }

    onSaveTopic = () => {
        let topic = this.state.topic
        topic.learning_goals = this.state.learning_goals
        topic.syllabus_sections = this.state.syllabus_sections
        topic.duration = this.getEntireTopicDuration(topic.syllabus_sections)
        // console.log(this.state.syllabus_sections);
        this.setState({
            loading: true,
            snackMessage: { type: null, message: null },

        })
        // console.log(topic);
        topicCreateUpdate(topic).then((data) => {
            this.setState({
                topic: { ...this.state.topic, ...data },
                syllabus_sections: this.updateSyllabusSections(this.state.syllabus_sections, data.syllabus_sections) || [],
                saveChanges: false,
                loading: false,
                snackMessage: { type: "success", message: "Topic succesfully saved" },

            })

            if (this.state.thumbnailFileData) {
                uploadTopicThumbnaileImage({
                    id: data.id,
                    user: data.user,
                    title: data.title,
                    thumbnail: this.state.thumbnailFileData
                }).then((tRes) => {
                    // console.log(tRes);
                }).catch((err) => {
                    this.setState({
                        snackMessage: { type: "error", message: readableServerError(err) },

                    })
                })
            }

        }).catch((err) => {
            console.log(err.response);
            this.setState({
                snackMessage: { type: "error", message: readableServerError(err) },
                loading: false,
            })
        })

        // console.log(topic);

    }

    updateTopicWithDuration = (syllabusSections) => {
        const topicDuration = this.getEntireTopicDuration(syllabusSections || this.state.syllabus_sections)
        // save other things on the topic that this may affect
        if (topicDuration !== this.state.topic.duration) {
            topicCreateUpdate({
                id: this.state.topic.id,
                duration: topicDuration,
                // below is required for the request
                user: getUserId(),
                title: this.state.topic.title,
            }).then((data) => {
                this.setState({
                    topic: { ...this.state.topic, ...data }
                })
            }).catch(err => {
                console.log(readableServerError(err));
            })
        }
    }

    updateSyllabusContents = (oldSyllabusContents, newSyllabusContents) => {
        let updatedSC = []

        for (let i = 0; i < newSyllabusContents.length; i++) {
            const s_con = newSyllabusContents[i];
            oldSyllabusContents[i].syllabus_content = s_con.id // TODO: this line seems redundant. test and remove if necessary

            updatedSC.push({
                ...oldSyllabusContents[i],
                ...s_con,
                content: (s_con.content && Object.keys(s_con.content).length !== 0) ? s_con.content : oldSyllabusContents[i].content
            })
        }
        return updatedSC
    }

    updateSyllabusSections = (oldSyllabusSections, newSyllabusSections) => {

        let updatedSS = []
        for (let i = 0; i < newSyllabusSections?.length; i++) {

            const s_sec = newSyllabusSections[i];
            let updatedSC = this.updateSyllabusContents(oldSyllabusSections[i].syllabus_contents, s_sec.syllabus_contents)
            updatedSS.push({ ...oldSyllabusSections[i], ...s_sec, loading: false, syllabus_contents: updatedSC })
        }
        return updatedSS
    }

    getEntireTopicDuration = (syllabusSections) => {
        let duration = 0
        for (let i = 0; i < syllabusSections.length; i++) {
            const s_sec = syllabusSections[i];
            duration += this.getSyllabusSectionDuration(s_sec.syllabus_contents)
        }
        // DEBUG: .toFixed returns a string instead of a float digit. 
        // DEBUG: .toFixed(10) is a control measure. If decimal points exceed 10, backend would throw an error
        return parseFloat(duration?.toFixed(10) || 0)
    }

    getSyllabusSectionDuration = (syllabusContents) => {

        let duration = 0

        for (let i = 0; i < syllabusContents.length; i++) {
            const s_con = syllabusContents[i];
            duration += convertDurationStringToSeconds(s_con.content?.duration || s_con.contentData?.duration || 0)
        }
        return duration || 0
    }


    render = () => {
        return (
            <CourseContainer>
                <SnackBar
                    open={this.state.snackMessage.message}
                    message={this.state.snackMessage.message}
                    type={this.state.snackMessage.type}
                    position="bottom-right"
                    timeout={4000}
                />
                <Prompt
                    when={this.state.saveChanges}
                    message={(location, action) => {
                        if (action !== "POP") {
                            return "You are leaving this page with unsaved work. Are you sure you want to do this?"
                        }
                    }
                    }
                />
                <div className="courseContent">
                    <div className="introHeader">

                        <Input placeholder="Topic Name"
                            width="60%"
                            margin="10px 35px"
                            textTransform="capitalize"
                            size="2em"
                            padding="5px 20px"
                            color="#923D41"
                            name="title"
                            value={this.state.topic.title || ""}
                            onChange={this.onTopicChange}
                        />


                        <Button
                            background="rgba(232, 232, 232, 1)"
                            color={this.state.topic.published ? "rgba(46, 49, 49, 1)" : "rgba(207, 0, 15, 1)"}
                            padding="8px 15px"
                            height="3em"
                            margin="1em 0"
                            boxShadow="3px 5px 6px #00000029"
                            radius="3em"
                            disabled={this.state.loading}
                            onClick={async () => {
                                // without await, this doesnot update state to save data
                                await this.setState({ topic: { ...this.state.topic, published: !this.state.topic.published } });
                                this.onSaveTopic()
                            }}
                        >
                            {this.state.loading ?
                                <>
                                    <BeatLoader color="#39903F" loading={this.state.loading} size={10} />
                                    Loading...
                                </>
                                :
                                <>{this.state.topic.published ? "Published" : "Publish"}
                                    <img
                                        src={this.state.topic.published
                                            ? PublishedIcon
                                            : UnPublishedIcon}
                                        width="15px"
                                        height="15px"
                                        alt="published/unpublished"
                                    /></>}

                        </Button>
                        <Button
                            height="3em"
                            radius="3em"
                            padding="5px 20px"
                            background={this.state.saveChanges ? "rgba(224, 130, 131, 0.2)" : "rgba(232, 232, 232, 1)"}
                            color={"rgba(46, 49, 49, 1)"}
                            margin="1em"
                            disabled={this.state.loading}
                            onClick={this.onSaveTopic}
                        >
                            {
                                this.state.loading ?
                                    <>
                                        <BeatLoader color="#fff" loading={this.state.loading} size={10} />
                                        Loading...
                                    </>
                                    :

                                    <>  {this.state.saveChanges ? "Save Changes" : "Saved!"}
                                        {!this.state.saveChanges && <img
                                            src={SavedIcon}
                                            width="15px"
                                            height="15px"
                                            alt="savedIcon"
                                        />}
                                    </>
                            }

                        </Button>


                    </div>

                    <Card display="flex" space="space-between" margin="1em">
                        <Card>
                            <HighlightedText margin="1em 1em" size="1em">
                                Instructor: {getFirstName()} {getLastName()}
                            </HighlightedText>
                        </Card>

                        <Card>
                            <HighlightedText margin="1em 1em 0.25em" size="1em" color="#535353">
                                Duration: {durationReadableFromNumber(this.state.topic.duration) || "Indeterminate"}
                            </HighlightedText>
                            <HighlightedText margin="0.5em 1em" size="0.6em" color="#923D41" textAlign="center">
                                Computes automatically on save
                            </HighlightedText>

                        </Card>
                        <Card padding="1em">
                            <label>
                                Level:
                                <select name="level" value={this.state.topic.level ?? "None"} onChange={this.onTopicChange}>
                                    <option value="Beginner">Beginner</option>
                                    <option value="Better Understand">Needs to better understand</option>
                                    <option value="Learn more">Wants to learn more</option>
                                    <option value="None">None</option>
                                </select>
                            </label>
                        </Card>
                    </Card>

                    <Card display="flex" margin="1em">
                        <Card margin="1em 1em" size="1em" width="55%">
                            <AshMidTitle title="Topic Description" />

                            <TextArea name="description"
                                margin="1em 0em 1em 1em"
                                placeholder="Type a description of the topic here"
                                rows={12} style={{ resize: 'none' }}
                                value={this.state.topic.description || ""}
                                onChange={this.onTopicChange}
                            />
                        </Card>

                        <Card margin="1em 1em" size="1em" width="40%">
                            <AshMidTitle title="What you'll learn" />
                            <ul>
                                <li>
                                    <Input
                                        placeholder="Type first learning outcome here"
                                        name={0}
                                        width="90%"
                                        value={this.state.learning_goals[0] || ""}

                                        onChange={this.onChangeLearningGoal} />
                                </li>
                                <li>
                                    <Input
                                        placeholder="Type second learning outcome here"
                                        name={1}
                                        width="90%"
                                        value={this.state.learning_goals[1] || ""}
                                        onChange={this.onChangeLearningGoal} />
                                </li>
                                <li>
                                    <Input
                                        placeholder="Type third learning outcome here"
                                        name={2}
                                        value={this.state.learning_goals[2] || ""}
                                        width="90%"
                                        onChange={this.onChangeLearningGoal} />
                                </li>
                            </ul>
                        </Card>

                    </Card>


                    <Card padding="2em 0" margin="1em">
                        <Card className="infoNote" background="rgba(190, 144, 212,0.5)" color="rgba(142, 68, 173, 1)" padding="1em" margin="1em">
                            <b>Note that</b>, currently, the platform does not support drag for rearranging syllabus contents.
                            Hence use the <em>order</em> field to order your contents. Thank you.
                            <br />
                            <br />
                            <b>Also note that,</b> creating a quiz is a farely thorough process hence, kindly use the <em>Quizzes</em> tab to create your quiz. Only come to add them to modules here. Thank you.
                        </Card>

                        <Card className="resp__so" background="#f1f8ff" color="black" padding="1em" margin="1em">

                            <b>Please note that</b> some of the features in setting up a topic can only be utilised on a device with bigger screen size. Thank you.

                        </Card>

                        <AshMidTitle title="Topic Syllabus" />

                        {
                            this.state.syllabus_sections.map((syllabusSection, i) =>
                                <SyllabusSection
                                    key={i}
                                    index={i}
                                    data={syllabusSection}
                                    onEdit={this.onEditSyllabusSection}
                                    length={this.state.syllabus_sections.length}
                                />
                            )
                        }
                        <Button
                            display="flex"
                            color="#923D41"
                            background="#fff"
                            border="2px dashed #923D41"
                            padding="0.5em"
                            margin="1em 2em"
                            space="space-between"
                            width="95%"
                            className="addModule"
                            onClick={this.onAddModule}
                        >
                            <img src={AddSectionIcon} width="30px" alt="add icon" />
                            <HighlightedText
                                size="16px"
                                weight="500"
                                color="#923D41"
                                lineHeight="1.5"
                                background="rgba(232, 232, 232, 1)"
                                padding="5px 10px"
                                radius="5px"
                                margin="0 0 0 1em"
                            >
                                ADD A MODULE
                            </HighlightedText>

                        </Button>



                    </Card>

                </div>


                <ExtraInfoTab
                    onAddDeleteLabel={this.onAddDeleteLabel}
                    labels={this.state.topic.labels}
                    value={this.state.label}
                    onVariableChange={this.onVariableChange}
                    onTopicChange={this.onTopicChange}
                    thumbnail={this.state.topic.thumbnail || this.state.thumbnail}
                    handleTopicImage={this.handleImages}
                    topicID={this.state.topic.id}
                />
                {this.state.newTopicModal && <AppModal
                    show={this.state.newTopicModal}
                    image={AddNewTopicIllustration}
                    message={"Create a new topic here. Make sure you are naming the topic with a unique name from other topics you have created."}
                    header={"Create new topic"}
                    body={<Input
                        placeholder="Topic name. Use a unique name for the new topic"
                        onChange={this.onTopicChange}
                        name="title"
                    />}
                    handleClose={() => this.props.history.push("/")}
                    primary={() => {
                        this.setState({
                            newTopicModal: false
                        })
                        this.onSaveTopic()
                    }}
                />}
            </CourseContainer>

        )
    }

}

export default Topic;

const CourseContainer = styled.div`
    margin: 1em;
    display: flex;
    width: 100%;
    position: relative;

    .courseContent{

        /* margin: 1em; */
        width: inherit;
        

        ul {
            list-style-type: square;
            color: black;
        }
        
        .introHeader{
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: sticky;
            top: 0;
            background-color: #F7F9FB;

            button{
                img{
                    margin: 0 0 0 0.5em;
                }
            }
        }

        label{
            color: black;
            margin: 1em;
            select{
                margin: 0 1em;
                padding: 0.5em;
                font-size: 1em;
            }

        }

    }

    .resp__so{
        display: none;
    }

    @media only screen and (max-width: 768px){
        display: block;
        margin: 5%;
        width: auto;
        .courseContent{
            width: 95%;
            .infoNote{
                background-color: rgba(190, 144, 212,0.5);
                padding: 3%;
            }
            .resp__so{
                background-color: #f1f8ff;
                padding: 3%;
                display: block;
            }
            div{
                display: block;
                margin: 2% 0;
                width: auto;
                padding: 2% 1% 1%;
                background-color: antiquewhite;
                h1{
                    width: 90%;
                    
                }
                textarea{
                    width: 80%;
                }
                input{
                    width: 80%;

                }

                .addModule{
                    width: 85%;
                }
                

            }
            .introHeader{
                display: contents;
                input{
                    width: 80%;
                    margin: 10px 0;

                }
            }
        }
    }

`

