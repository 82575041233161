import React from 'react';
import styled from 'styled-components';
import { HighlightedText } from '../../components/highlightedText';
import { Link } from 'react-router-dom'
import { Card } from "../../components/card";
import QuizListItem from "../../components/quiz/quizListItem";
import AddQuizIcon from '../../assets/icons/add_white.svg'
import { getQuizzes } from '../../utils/api/quizApi';
import SnackBar from 'my-react-snackbar';
import RingLoader from "react-spinners/RingLoader";
import { readableServerError, removeItemsWithPrefixFromLocal } from '../../utils/utilFnx';




class Quizzes extends React.Component {

    constructor(props) {
        super();
        this.state = {
            quizzes: [],
            loading: false,
            errorMessage: null,
        }
    }


    componentDidMount = () => {
        this.getUserQuizData()
    }

    getUserQuizData = () => {
        this.setState({
            loading: true
        })
        getQuizzes()
            .then(data => {
                // console.log(data)
                removeItemsWithPrefixFromLocal("quizList-")

                this.setState({
                    quizzes: data,
                    loading: false,
                })
            }).catch((err) => {
                this.setState({
                    loading: false,
                    errorMessage: readableServerError(err)
                })


            });
    }


    render = () => {

        return (
            <CoursesContainer>
                <SnackBar
                    open={this.state.errorMessage}
                    message={this.state.errorMessage}
                    type='error'
                    position="bottom-right"
                    timeout={2000}
                />
                <Card display="flex" alignItems="center" space="space-between" margin="1em" background="transparent">

                    <HighlightedText margin="0.5em" width="70%">Quiz List</HighlightedText>
                    <Link to="/quiz/lab">
                        <Card display="flex" background="transparent" alignItems="center">

                            <img src={AddQuizIcon} width="20px" height="20px" alt="addQuiz" />
                            <HighlightedText className="resp__bo" color="#fff" size="15px" margin="0em 1em">
                                Create Quiz
                            </HighlightedText>
                        </Card>
                    </Link>
                </Card>

                {this.state.loading ?
                    <HighlightedText textAlign="center" margin="1em" weight="normal" size="15px">
                        <RingLoader color="#f03434" loading={this.state.loading} size={30} />
                        <br />
                        <br />

                        Loading...
                    </HighlightedText>
                    : this.state.quizzes.length === 0
                        ? "You have not created any quiz yet."
                        :
                        this.state.quizzes.map((quiz, i) => <QuizListItem key={i} data={quiz} />)
                }


            </CoursesContainer>

        )
    }

}

export default Quizzes

const CoursesContainer = styled.div`

    margin: 1em;

    .courseDiv{
        display: flex;
        flex-flow: wrap;
    }

    a{
        text-decoration: none;
        background-color: #39903F;
        margin: 0.5em 1em; 
        padding: 10px;
        border-radius: 0.25em;
    }
`

