import React, { Component } from 'react';
import styled from 'styled-components';
import { HighlightedText } from './highlightedText';
import { NavLink } from 'react-router-dom'
/* imported images*/
import TopicIcon from "../assets/icons/courses_wine.svg";
import SettingsIcon from "../assets/icons/settings_wine.svg";
import FeedbackIcon from "../assets/icons/feedback.svg"
import AddVideoIcon from "../assets/icons/add_video.svg"
import AnalyticsIcon from "../assets/icons/analytics.svg"
import QuizIcon from "../assets/icons/quiz.svg"

class AdminSideBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }


    render() {
        return (
            <SideBar width="15%" className={this.props.className} onClick={()=>this.props.onClick && this.props.onClick()}>
                <HighlightedText className="skideoTitle" size="30px" textAlign="center" color="" margin="0.5em 0">
                    Skideo Lab
                    {/* TODO: add "pun-intended" subscript in the future for Skideo Studio */}
                </HighlightedText>

                <div className="topDiv">
                    <NavLink
                        activeClassName="active"
                        to="/topic/create">
                        <img src={AddVideoIcon} alt="icon" width="20px" height="20px" />
                        <HighlightedText size="15px" className="center-text" color="#923D41" margin="1em">
                            Create Topic
                        </HighlightedText>

                    </NavLink>
                    <NavLink
                        activeClassName="active"
                        to="/topic/list"
                        isActive={(match, location) => {
                            return location.pathname.includes(`/topic/list`)
                                || location.pathname.includes(`/topic/edit`)
                        }}
                        >
                        <img src={TopicIcon} alt="icon" width="20px" height="20px" />
                        <HighlightedText size="15px" className="center-text" color="#923D41" margin="1em">
                            Topics
                        </HighlightedText>

                    </NavLink>

                    <NavLink activeClassName="active"
                        to="/analytics"
                    >
                        <img src={AnalyticsIcon} alt="icon" width="20px" height="20px" />
                        <HighlightedText size="15px" className="center-text" color="#923D41" margin="1em">
                            Analytics
                        </HighlightedText>

                    </NavLink>

                    <NavLink activeClassName="active"
                        to="/settings"
                    >
                        <img src={SettingsIcon} alt="icon" width="20px" height="20px" />
                        <HighlightedText size="15px" className="center-text" color="#923D41" margin="1em">
                            Account Settings
                        </HighlightedText>

                    </NavLink>


                    <hr />
                    <NavLink activeClassName="active"
                        to="/quizzes"
                        isActive={(match, location) => {
                            return (location.pathname.includes(`/quizzes`)
                                || location.pathname.includes(`/quiz`) )
                                && !location.pathname.includes(`/analytics`)
                        }}
                    >
                        <img src={QuizIcon} alt="icon" width="20px" height="20px" />
                        <HighlightedText size="15px" className="center-text" color="#923D41" margin="1em">
                            Quizzes
                        </HighlightedText>

                    </NavLink>

                    <hr />
                    <NavLink activeClassName="active"
                        to="/feedback"
                    >
                        <img src={FeedbackIcon} alt="icon" width="20px" height="20px" />
                        <HighlightedText size="20px" className="center-text" color="#707070" margin="1em">
                            Feedback
                            </HighlightedText>

                    </NavLink>
                </div>



            </SideBar>
        )
    }
}

export default AdminSideBar





const SideBar = styled.div`
    min-width: 15%;
    height: 100vh;
    box-shadow: 0px 3px 6px #00000029;
    background-color: #fff;
    z-index:1;

    hr{
        margin: 1em;
    }

    .middleDiv{
        position: absolute;
        top: 20%;
        text-align-last: center;
        padding: 0.5em;
        margin: 0.5em;

    }

    .feedbackDiv{
        position: absolute;
        top: 30%;
        text-align-last: center;
        padding: 0.5em;
        margin: 0.5em;

    }

    .bottomDiv{
        /* position: absolute;
        bottom: 20%; */
        text-align-last: center;
        padding: 0.5em;
        margin: 0.5em;
    }

    a{
        align-items: center;
        text-decoration: none;
        display: flex;
        padding: 0em 1em;

        &.active { // & - making a class name inside the tag itself
            background-color: rgba(128,128,128, 0.2);
            border-radius: 0.3em;
            margin: 0em 0.5em;
                
            .img {
                    filter: drop-shadow(0px 0px 13px #fff) brightness(1.2); 
            }
            h1 {
                font-weight: bold;
            }
        }

    }


    @media only screen and (min-width: 768px) and (max-width: 1100px){
        img{
            width: 15px;
            height: 15px;
        }
        a{
            padding: 0 0.8em;
            &.active{
                margin: 0em 0.3em;
            }
            h1 {
                font-size: 14px;
            }
        }

        .skideoTitle{
            font-size: 20px
        }

        h1{
            font-size: 12px;
            margin: 1em 0.5em;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        
    }


`

