
import React, { Component } from 'react';
import { Card } from '../../components/card'
import { Button } from '../../components/uiComponents/button'
import { login } from '../../utils/api/accountApi'
import styled from 'styled-components';
import { HighlightedText } from '../../components/highlightedText';
import { Input } from '../../components/input';
import { readableServerError, setItemToLocal } from '../../utils/utilFnx';
import ClipLoader from "react-spinners/ClipLoader";


class AdminLogin extends Component {


    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            loading: false,
            errorMessage: null
        }
    }



    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            errorMessage: null,
        });
        // console.log(this.state)
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true })
        login(this.state.email, this.state.password)
            .then(data => {
                // console.log(data);
                this.setState({ loading: false })
                setItemToLocal("a_bearer", data.token)
                setItemToLocal("email", this.state.email)
                setItemToLocal("f_name", data.first_name)
                setItemToLocal("l_name", data.last_name)
                setItemToLocal("username", data.username)
                setItemToLocal("u_id", data.id)
                setItemToLocal("profile_pic",data.profile_pic) // set profile pic 


                this.props.history.push('/')
            })
            .catch(error => {
                console.log(error.message)
                this.setState({
                    loading: false,
                    errorMessage: readableServerError(error),
                })
            });
    }


    render() {

        return (
            <LoginFormContainer>
                <HighlightedText textAlign="center" color="#923D41">
                    Skideo Lab
                </HighlightedText>
                <Card shadow="0px 12px 30px #923D411A" padding="2em 1em 1em">
                    <form onSubmit={this.handleSubmit}>
                        <Input
                            placeholder="Email"
                            type="email"
                            name="email"
                            onChange={this.handleChange}
                            required />

                        <Input
                            placeholder="Password"
                            type="password"
                            name="password"
                            onChange={this.handleChange}
                            required />
                        {this.state.errorMessage && <p>{this.state.errorMessage}</p>}

                        <Button
                            background="#923D41"
                            radius="30px"
                            margin="3em auto 3em"
                            width="90%"
                            disabled={this.state.loading}
                        >
                            <ClipLoader color="#fff" loading={this.state.loading} size={10} />
                            {!this.state.loading && <span>LOGIN</span>}
                        </Button>

                        <a href="/auth/forgot" className="forgotPassword" >Forgot password?</a>

                    </form>
                </Card>
                <a href="/auth/register">Don't have an account? Sign up.</a>
            </LoginFormContainer>

        );
    }
}
export default AdminLogin;



const LoginFormContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:25%;

    input{
        border: 1px solid black;
        border-radius: 3px;
        display: block;
        padding: 10px 20px;
        margin: 25px auto;
        width: 80%;
        height: 1.5em;

        text-align: left;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        color: #923D41;
    }


    input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #923D41;
        font-weight: normal;
        opacity: 1; /* Firefox */
      }


    button{
        display: block;
        height: 40px;
    }

    a{
        text-decoration: none;
        color: #923D41;
        font-size: 12px;
        text-align: center;
        display: block;
        margin-top: 2em;
    }

    .forgotPassword{
        color: #240046;
    }

    p{
        color: #923D41;
        text-align: center;
        margin: 0 auto;
        background: rgba(146, 61, 65, 0.1);
        width: fit-content;
        padding: 0.5em;
        border-radius: 0.5em
    }


    @media only screen and (max-width: 768px){
        display: block;
        margin: 20px auto;
        width:90%;

    }


`

