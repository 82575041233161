import React from 'react';
import styled from 'styled-components';
import { HighlightedText } from "../highlightedText";
import QuizIcon from '../../assets/icons/quiz-paper-filled-wine.svg';
import TickIcon from '../../assets/icons/checkmark_green.svg';
import UnSelectableIcon from '../../assets/icons/delete-rounded-wine.svg';
import { getItemFromLocal } from '../../utils/functions/storage';
import { readableServerError, removeItemsWithPrefixFromLocal, setItemToLocal } from '../../utils/utilFnx';
import { Button, PassButton } from "../uiComponents/button";
import { getQuizzes } from '../../utils/api/quizApi';
import SnackBar from 'my-react-snackbar';
import { Link } from 'react-router-dom'



class QuizModal extends React.Component {

    constructor(props) {
        super();
        this.state = {
            id: null,
            title: "",
            quizzes: [],
            snackMessage: { type: null, message: null },
        }
    }

    componentDidMount = () => {
        // console.log(this.props.data);
        // saving props data to state. 
        this.setState({
            ...this.props.data
        })
        const cachedQuizzes = getItemFromLocal(`quizList-${new Date().toISOString().slice(0, 10)}`)
        if (!cachedQuizzes) {
            removeItemsWithPrefixFromLocal("quizList-")
            getQuizzes().then(data => {
                // console.log(data);
                setItemToLocal(`quizList-${new Date().toISOString().slice(0, 10)}`, data)
                this.setState({
                    quizzes: data
                })
            }).catch((err) => {
                console.log(readableServerError(err));
                this.setState({
                    snackMessage: { type: "error", message: readableServerError(err) },
                })

            })
        } else {
            this.setState({
                quizzes: cachedQuizzes
            })
        }
    }


    handleSubmitQuiz = (e) => {
        this.props.onSubmitQuiz(this.props.index, { content: this.state.id, contentData: this.state, modal: false })
    }

    saveBeforeNavigate =(e)=>{
        this.props.onSubmitQuiz(this.props.index, { modal: false }, "save-all")
    }


    render() {
        // console.log(this.props);
        return (
            <ModalContainter
                onClick={e => { e.stopPropagation() }}
                width="100%"
                bottom="0"
                right="0"
                top="0"
                left="0"
                radius="0"
                display={this.props.show ? "block" : "none"}

            >
                <SnackBar
                    open={this.state.snackMessage.message}
                    message={this.state.snackMessage.message}
                    type={this.state.snackMessage.type}
                    position="bottom-right"
                    timeout={2000}
                />
                <div className="modalBody">
                    <div className="container">
                        <div className="leftPane">
                            <img src={QuizIcon} alt="Quiz upload" />
                        </div>
                        <div className="rightPane">
                            <div className="formTop">
                                <h3 className="title">Choose quiz to upload</h3>
                                <p>
                                    Make sure you have already <b>created and published</b> the quiz from the <em>quiz</em> section else, you would not be able to select them.
                                </p>
                            </div>

                            {this.state.quizzes.length === 0 ?
                                <Link to="/quiz/lab" onClick={this.saveBeforeNavigate} target="_blank" rel="noopener noreferrer">
                                    Create a new quiz
                                </Link>
                                :
                                this.state.quizzes.map((quiz, i) => <this.quizListItem key={i} data={quiz} />)
                            }

                            <div
                                className="buttonContainer">
                                <PassButton
                                    color="#535353"
                                    background="#ffffff"
                                    border="1px solid #535353"
                                    onClick={this.props.handleClose}
                                >
                                    {this.props.primaryButton || 'Cancel'}
                                </PassButton>

                                <PassButton
                                    disabled={!this.state.id}
                                    onClick={this.handleSubmitQuiz}
                                    color={this.state.id ? "#fff" : "#535353"}
                                    background={this.state.id ? "#535353" : "#e8e8e8"}>
                                    {'Done'}
                                </PassButton>

                            </div>
                        </div>
                    </div>

                </div>

            </ModalContainter>
        );
    }

    quizListItem = (props) => {
        return <Button
            background={this.state.id === props.data.id ? "#26a65b10" : "#fafbfc"}
            border="1px solid #1b1f2326"
            padding="10px"
            margin="5px"
            width="100%"
            color="#24292e"
            radius="10px"
            space="space-between"
            display="flex"
            disabled={!props.data.published}
            onClick={(e) => this.setState({ ...props.data })}
        >
            <HighlightedText
                color="#24292e"
                weight="normal"
                size="15px"
                width="70%"
                textAlign="left"
            >
                {props.data.title}
            </HighlightedText>

            {
                props.data.published ?
                    this.state.id === props.data.id &&
                    <img
                        src={TickIcon}
                        alt="chosen"
                        width="18px"
                        height="18px"
                    />
                    :
                    <>
                        <HighlightedText color="#923D41" weight="bold" size="13px">
                            Unpublished
                        </HighlightedText>
                        <img src={UnSelectableIcon} alt="chosen" width="18px" height="18px" />
                    </>
            }

        </Button>;
    }
}


export default QuizModal





const ModalContainter = styled.div`
    width: ${({ width }) => width || "100%"};
    position:fixed;
    background: ${({ background }) => background || "rgba(0, 0, 0, 0.6)"};
    color: ${({ color }) => color || "#ffffff"};
    border-radius: ${({ radius }) => radius || "0px"};
    height: auto;
    top: ${({ top }) => top};
    left: ${({ left }) => left};
    bottom: ${({ bottom }) => bottom};
    right: ${({ right }) => right};
    transform: ${({ transform }) => transform};
    z-index: 1;
    display: ${({ display }) => display} !important; // make modal work in responsivenss


    .modalBody{

        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        margin: 0 50px;

        .container {
            display: flex;
            width: 80%;
            height: 70%;

        }

        .leftPane{
            background-color: #fff;
            width: 50%;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            padding: 20px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: black;

            img {
                height: 175px;
            }
        }

        .rightPane{
            background-color: #F7F7F7;
            width: 100%;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            box-sizing: border-box;
            padding: 0px 8%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            overflow: hidden scroll;


            .buttonContainer{
                margin: 20px;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;  
            }

            .formTop {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                margin-bottom: 30px;

                input {
                    margin-top: 20px;
                    border-bottom: 1px solid  #A93B3F;
                    color: #A93B3F;
                    margin-bottom: 10px;
                    width: 100%;
                }

                input::placeholder{
                    color: #A93B3F;
                }
            }

            .title {
                color: #535353;
                font-size: 32px;
                margin-bottom: 0;
            }
            p{
                color: #767676;
                width: 90%;
                margin-right: 10%;
                font-size: 15px;
                line-height: 1.6;
            }

            a{
                text-decoration: none;
                background-color: #ebf5ff;
                border: 1px solid #0366d650;
                color: #0366d6;
                padding: 10px 15px;
                border-radius: 10px;

                &:hover{
                    text-decoration: underline;
                }
            }
        }
        

    }
    @media only screen and (max-width: 768px){
        background-color: white !important;
        margin: 0 auto !important;
        div{
            background-color: white !important;
        }

        .modalBody{
            margin: 0 10px;
            .container{
                display: block;
                width: 100%;
                height: 90%;
                margin: 0px auto;
                text-align: -webkit-center;
                overflow: auto;
            }

            .leftPane{
                width: 90%;
                border-bottom-left-radius: 0;
                border-top-right-radius: 15px;

                img {
                    height: 125px;
                }
            }
            .rightPane{
                border-top-right-radius: 0;
                border-bottom-left-radius: 15px;
                width: 90%;
            }
        }
    }

    
`