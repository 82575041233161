import React from 'react';
import { Card } from "../../components/card";
import { HighlightedText } from '../../components/highlightedText';
import { durationReadableFromNumber } from '../../utils/utilFnx';



class SingleAnalytics extends React.Component {

    render =()=>{
        return this.props.singles.map((single, s_i) => {
            return <Card key={s_i} margin="1em" width={`${30 / this.props.singles.length}%`}>
                <HighlightedText weight="600" color="#24252a" size="16px">
                    {single.unit === "time" ? durationReadableFromNumber(single.data) || 0 : single.data || 0}
                </HighlightedText>
                <HighlightedText weight="600" color="#2c3e50" size="14px" >
                    {single.presentation}
                </HighlightedText>
            </Card>
        })
    }
}
export default SingleAnalytics