import styled from 'styled-components';

export const Button = styled.button`
color: ${({ color }) => color || "#ffffff"};
background-color: ${({ background }) => background || "#923d41"};
border: ${({ border }) => border || 0};
padding: ${({ padding }) => padding || "7px 53px"};
margin: ${({ margin }) => margin || "0px 0px"};
border-radius: ${({ radius }) => radius || "2px"};
width: ${({ width }) => width || "auto"};
height: ${({ height }) => height || "auto"};
display: ${({ display }) => display};
justify-content: ${({space}) => space};
align-items: ${({alignItems}) => alignItems};
box-shadow: ${({boxShadow}) => boxShadow};

  &:disabled{
    background-color: #e1e4e8;
    cursor: no-drop;
  }

`;


export const PassButton = styled.button`
  color: ${({ color }) => color || "#767676"};
  background-color: ${({ background }) => background || "#F7F7F7"};
  padding: ${({ padding }) => padding || "10px 30px"};
  border: ${({ border }) => border || "0"};
  // margin: ${({ margin }) => margin || "0px 0px 0px"};
  border-radius: 6px;
  cursor: pointer;
  display: flex;    
  flex-direction: row;

  img{
      margin-right: 15px;
      margin-bottom: -1px;
  }

  a {
          text-decoration: none;
          color: ${({ color }) => color || "#8f3336"};
  }


`;

