import React from 'react';
import { HighlightedText, HightlightedSpan } from '../highlightedText';
import { Card } from "../card";
import { SuperScript } from "../uiComponents/text";
import { Button } from '../uiComponents/button';
import CancelIcon from '../../assets/icons/cancel.svg'
import TickIcon from '../../assets/icons/tick.svg'
import { Input } from "../input";
import CourseImg from '../../assets/images/defaultTopic.jpg'
import styled from 'styled-components';

class ExtraInfoCard extends React.Component {

    constructor(props) {
        super();
        this.state = {
            topicData: {},

        }
    }



    render = () => {
        return <ExtraCardContainer >
            <HighlightedText margin="0.5em" size="18px">
                Settings and Information
            </HighlightedText>

            <Card margin="1em auto" display="block" width="inherit">
                <HighlightedText color="grey" size="0.75em" >
                    Image/Thumbnail
                </HighlightedText>
                <img src={this.props.thumbnail ?? CourseImg} width="100px" height="60px" alt="Topic Thumbnail" />
                <input className="fileUpload" width="auto" padding="10px 5px" margin="10px 0" type="file" onChange={this.props.handleTopicImage} />
            </Card>
            <HighlightedText color="grey" size="0.75em" margin="1em">
                Topic url: <a 
                href={`${process.env.REACT_APP_SKIDEO_TV_STUDENT}/topic/${this.props.topicID}/overview`} 
                target="_blank" 
                rel="noopener noreferrer"
                >link</a>
            </HighlightedText>
            <hr />
            <HighlightedText color="green" size="15px" margin="0.5em 0 0em">
                Monetization!
            </HighlightedText>
            <Card color="grey" size="0.75em" weight="normal" margin="0.2em">
                <Button margin="10px" width="70%" padding="7px 20px">
                    <img src={TickIcon} alt="tick icon" width="10px" height="10px" />
                    Paid per view
                </Button>
                <Button disabled={true} style={{ cursor: "no-drop" }} margin="10px" width="70%" padding="7px 20px" background="rgba(243, 241, 239, 1)" color="#000">
                    One time payment
                    <SuperScript>soon</SuperScript>
                </Button>

            </Card>
            <hr />

            <HighlightedText size="15px" margin="0.5em 0">
                Labels
            </HighlightedText>
            <HightlightedSpan color="#923D41" size="12px">Keywords to the topic in a search</HightlightedSpan>

            <Card border="1px dashed grey" padding="10px">
                <Card display="flex" flexFlow="wrap">
                    {this.props.labels.map((label, i) => {
                        return (

                            <Card key={i} display="flex">
                                <HightlightedSpan
                                    background="rgba(232, 236, 241, 1)"
                                    color="rgba(46, 49, 49, 1)"
                                    size="12px"
                                    weight="normal"
                                    padding="5px 5px 5px 10px"
                                    margin="3px"
                                    radius="1em"
                                >
                                    {label}
                                    <Button
                                        background="rgba(231, 76, 60, 1)"
                                        padding="2px 5px"
                                        margin="0px 0px 0px 7px"
                                        radius="10em"
                                        onClick={(e) => this.props.onAddDeleteLabel(label, "delete")}
                                    >
                                        <img src={CancelIcon} alt="cancel icon" width="8px" />
                                    </Button>
                                </HightlightedSpan>

                            </Card>
                        )
                    })
                    }

                </Card>
                <Card display="flex" >
                    <Input padding="5px 10px" value={this.props.value} radius="3px 0 0 3px" name="label" onChange={this.props.onVariableChange} />
                    <Button color="white" padding="5px" margin="25px auto" radius="0 3px 3px 0" onClick={this.props.onAddDeleteLabel}>Add</Button>
                </Card>
            </Card>

        </ExtraCardContainer>

    }
}

export default ExtraInfoCard


const ExtraCardContainer = styled.div`
    width: 25%;
    height: fit-content;
    margin: 2em 1em 1em;
    padding: 1em;
    background-color: white;
    position: sticky;
    top: 10%;
    text-align: center;
    
    @media only screen and (max-width: 768px){

        width: min-content;
        margin: 5% 1% 1%;
        padding: 1em;
        .fileUpload{
            border: 1px solid black;
            border-radius: 3px;
            display: block;
            padding: 10px 5px;
            margin: 10px 0;
            width: auto;
            height: 1.5em;
            text-align: left;
            font-size: 14px;
            text-transform: none;
            font-weight: bold;
            letter-spacing: 0;
            color: #000;
        }
    }

`