
import React, { Component } from 'react';
import styled from 'styled-components';
import { HighlightedText } from '../../components/highlightedText';
import TopicCard from "../../components/courseCard";
import { getTopics } from '../../utils/api/topicApi';
import { getItemFromLocal, readableServerError } from '../../utils/utilFnx';
import SnackBar from 'my-react-snackbar';
import PulseLoader from "react-spinners/PulseLoader";
import { Link } from 'react-router-dom';

class TopicList extends Component {

    constructor(props) {
        super();
        this.currentBroadCastID = "beta0.1"
        this.state = {
            topics: [],
            loading: false,
            errorMessage: null,
            showBroadcast: getItemFromLocal("broadCast") !== this.currentBroadCastID
        }
    }


    componentDidMount = () => {

        this.getTopicsData();
    }

    getTopicsData = () => {
        this.setState({
            loading: true
        })
        getTopics()
            .then(data => {
                // console.log(data)
                this.setState({
                    topics: data,
                    loading: false,
                })
            }).catch((err) => {
                this.setState({
                    loading: false,
                    errorMessage: readableServerError(err)

                })
            });
    }


    render = () => {

        return (
            <TopicsContainer>
                <SnackBar
                    open={this.state.errorMessage}
                    message={this.state.errorMessage}
                    type='error'
                    position="bottom-right"
                    timeout={2000}
                />
                {/* TODO: take out if a better way of broadcasting new is found */}
                {/* {this.state.showBroadcast &&
                    <Card
                        margin="1em"
                        padding="1em"
                        background="#fffadb"
                        display="flex"
                        space="space-between">

                        <HighlightedText weight="normal" size="15px">
                            Welcome to the <b>Beta testing of Skideo</b>.
                            Please note that a stable server is currently being purchased. As such, you may experience slow loading and refreshing. Please bear with us.
                            <br />
                            <br />
                            Also, please refrain from putting legitimate data in the fields here. Random data can do for now.
                            You would be alerted when this is solved. Thank you.
                        </HighlightedText>

                        <Button
                            padding="10px"
                            height="min-content"
                            onClick={() => {
                                this.setState({
                                    showBroadcast: false
                                })
                                setItemToLocal("broadCast", this.currentBroadCastID)
                            }}
                        >
                            Close
                        </Button>
                    </Card>} */}

                <HighlightedText margin="1em"> Topics Created</HighlightedText>

                {this.state.loading ?
                    <center>
                        <PulseLoader color="#f03434" loading={this.state.loading} size={20} />
                        <br />

                        Loading...
                    </center>
                    :
                    this.state.topics.length === 0
                        ?
                        <center>
                            <p>You have not created any topic yet.</p>
                            
                            <Link to="/topic/create" className="newTopicLink">
                                Create a new topic
                            </Link>

                        </center>
                        :
                        <div className="courseDiv">
                            {
                                this.state.topics.map((topic, i) => <TopicCard key={i} data={topic} />)
                            }
                        </div>
                }

            </TopicsContainer>

        )
    }

}

export default TopicList

const TopicsContainer = styled.div`

    margin: 1em;

    .courseDiv{
        display: flex;
        flex-flow: wrap;
        justify-content: start;

    }

    a{
        text-decoration: none;
    }

    .newTopicLink{
        text-decoration: none;
        background-color: #ebf5ff;
        border: 1px solid #0366d650;
        color: #0366d6;
        padding: 10px 15px;
        border-radius: 10px;

        &:hover{
            text-decoration: underline;
        }
    }

    @media only screen and (max-width: 400px){
        .courseDiv{
            justify-content: center;
        }
    }
`

