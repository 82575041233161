
import React from 'react';
import { HighlightedText } from '../highlightedText';
import { Card } from "../card";
import { Input } from "../input";
import { Button } from '../uiComponents/button'
import SavedIcon from '../../assets/icons/checkmark-settings_green.svg'
import styled from 'styled-components';
import HashLoader from "react-spinners/HashLoader";
import SnackBar from 'my-react-snackbar';
import { updatePasswordData, updateUserAccountProfile } from '../../utils/api/accountApi';
import { getItemFromLocal, readableServerError, setItemToLocal } from '../../utils/utilFnx';



class Security extends React.Component {

    constructor(props) {
        super();
        this.state = {
            password: {
                password: "",
                new_password: "",
                confirm_new_password: "",
            },
            privacy: {
                display_real_name: getItemFromLocal("profile")?.display_real_name ?? true,
                public_profile: getItemFromLocal("profile")?.public_profile ?? true,
            },
            savePasswordChanges: false,
            savePrivacyChanges: false,
            loadingPassword: false,
            loadingPrivacy: false,
            snackMessage: { type: null, message: null },


        }
    }

    handlePasswordChange = (e) => {
        this.setState({
            password: { ...this.state.password, [e.target.name]: e.target.value, },
            savePasswordChanges: true,
        });
    }

    onSavePasswordChanges = () => {
        this.setState({
            loadingPassword: true,
        })
        if (this.state.password.new_password !== this.state.password.confirm_new_password) {
            this.setState({
                loadingPassword: false,
                snackMessage: { type: "error", message: "New passwords do not match" },
            })
        } else {
            updatePasswordData(this.state.password).then((data) => {
                this.setState({
                    loadingPassword: false,
                    savePasswordChanges: false,
                    snackMessage: { type: "success", message: "Password changed successfully." },
                })
            }).catch((err)=>{
                this.setState({
                    loadingPassword: false,
                    snackMessage: { type: "error", message: readableServerError(err) },
                })
            })


        }
    }
    onsavePrivacyChanges = () => {
        updateUserAccountProfile(this.state.privacy).then((data) => {
            setItemToLocal("profile", data)
            this.setState({
                loadingPrivacy: false,
                savePrivacyChanges: false,
                snackMessage: { type: "success", message: "Privacy settings saved successfully." },
            })
        }).catch((err)=>{
            this.setState({
                loadingPassword: false,
                snackMessage: { type: "error", message: readableServerError(err) },
            })
        })
    }

    render = () => {
        return (

            <SecurityContainer>
                <SnackBar
                    open={this.state.snackMessage.message}
                    message={this.state.snackMessage.message}
                    type={this.state.snackMessage.type}
                    position="bottom-right"
                    timeout={5000}
                />
                <Card>
                    <HighlightedText color="#923D41" size="25px" margin="10px 0">
                        Security Information
                    </HighlightedText>
                </Card>

                <hr />

                <HighlightedText color="#2e3131" size="25px" margin="10px 0">
                    Change password
                </HighlightedText>

                <Card>
                    <Card margin="10px 0px">
                        <HighlightedText color="black" size="17px" margin="10px 0">
                            Current Password
                        </HighlightedText>
                        <Input
                            width="30%"
                            margin="10px 0"
                            type="password"
                            placeholder="Input current password"
                            onChange={this.handlePasswordChange}
                            name="password"
                            required={true}
                            value={this.state.password.password} />
                        <HighlightedText color="#923D41" size="9px" margin="10px 0">
                            Password of the current account
                        </HighlightedText>
                    </Card>

                    <Card margin="10px 0px">
                        <HighlightedText color="black" size="17px" margin="10px 0">
                            New Password
                        </HighlightedText>
                        <Input
                            width="30%"
                            margin="10px 0"
                            type="password"
                            required={true}
                            placeholder="Input new password"
                            onChange={this.handlePasswordChange}
                            name="new_password"
                            value={this.state.password.new_password}
                        />
                        <HighlightedText color="black" size="17px" margin="10px 0">
                            Confirm New Password
                        </HighlightedText>
                        <Input
                            width="30%"
                            margin="10px 0"
                            type="password"
                            required={true}
                            placeholder="Confirm new password"
                            onChange={this.handlePasswordChange}
                            name="confirm_new_password"
                            value={this.state.password.confirm_new_password}
                        />

                        <HighlightedText color="#923D41" size="9px" margin="10px 0">
                            Confirm new password to make sure you got it right.
                        </HighlightedText>
                    </Card>


                    <Card margin="2em 0 3em">
                        {this.state.loadingPassword ?
                            <HashLoader color="#f03434" loading={this.state.loading} size={30} />
                            :

                            <Button
                                height="3em"
                                padding="5px 20px"
                                background={this.state.savePasswordChanges ? "#39903F" : "rgba(232, 232, 232, 1)"}
                                color={this.state.savePasswordChanges ? "#fff" : "rgba(46, 49, 49, 1)"}
                                margin="1em"
                                onClick={this.onSavePasswordChanges}
                            >
                                {this.state.savePasswordChanges ? "Save Password Changes" : "Saved!"}
                                {!this.state.savePasswordChanges &&
                                    <img
                                        src={SavedIcon}
                                        width="15px"
                                        height="15px"
                                        alt="savedIcon"
                                    />}
                            </Button>
                        }
                    </Card>

                </Card>

                <hr />

                <Card margin="10px 0">
                    <HighlightedText color="#923D41" size="25px" margin="10px 0">
                        Privacy Information
                    </HighlightedText>
                    <br />
                    <HighlightedText color="black" size="10px" margin="10px 0 0" display="flex" alignItems="center">
                        <Input
                            width="1.5em"
                            height="1.5em"
                            margin="10px"
                            type="checkbox"
                            name="display_real_name"
                            checked={this.state.privacy.display_real_name}
                            onChange={(e) => this.setState({ privacy: { ...this.state.privacy, [e.target.name]: e.target.checked }, savePrivacyChanges: true })}
                        />
                        Display your real name on your profile
                    </HighlightedText>
                    <HighlightedText color="#923D41" size="9px" margin="10px">
                        If unchecked, your  username will be displayed instead of your full name across all mentions on the platform.
                    </HighlightedText>
                    <br />
                    <HighlightedText color="black" size="10px" margin="10px 0 0" display="flex" alignItems="center">
                        <Input
                            width="1.5em"
                            height="1.5em"
                            margin="10px"
                            checked={this.state.privacy.public_profile}
                            type="checkbox"
                            name="public_profile"
                            onChange={(e) => this.setState({ privacy: { ...this.state.privacy, [e.target.name]: e.target.checked }, savePrivacyChanges: true })}

                        />
                        Allow everyone to see your profile
                    </HighlightedText>
                    <HighlightedText color="#923D41" size="9px" margin="10px">
                        If unchecked, your profile will be private and no one except you will be able to view it.
                    </HighlightedText>
                </Card>


                <Card margin="2em 0">
                    {
                        this.state.loadingPrivacy ?
                            <HashLoader color="#f03434" loading={this.state.loading} size={30} />
                            :
                            <Button
                                height="3em"
                                padding="5px 20px"
                                background={this.state.savePrivacyChanges ? "#39903F" : "rgba(232, 232, 232, 1)"}
                                color={this.state.savePrivacyChanges ? "#fff" : "rgba(46, 49, 49, 1)"}
                                margin="1em"
                                onClick={this.onsavePrivacyChanges}
                            >
                                {this.state.savePrivacyChanges ? "Save Privacy Changes" : "Saved!"}
                                {!this.state.savePrivacyChanges &&
                                    <img
                                        src={SavedIcon}
                                        width="15px"
                                        height="15px"
                                        alt="savedIcon"
                                    />}
                            </Button>

                    }

                </Card>



            </SecurityContainer>

        )
    }
}


export default Security



const SecurityContainer = styled.div`

    padding: 1em 3em 3em;
    margin: 1em 1em 3em;
    background-color: white;

    button{
        img{
            margin: 0px 0px 0px 10px;
        }
    }

    @media only screen and (max-width: 768px){
        display: block;
        margin: 5% 0%;
        width: auto;
        h1{
            width: 90%;
            margin: 2%;
        }
        
        input{
            width: auto;
            margin: 5% 2px;
        }
    }
`