
import React from 'react';
import styled from 'styled-components';
import { HighlightedText } from '../highlightedText';
import { Card } from "../card";
import { Input } from "../input";
import { Button } from '../uiComponents/button'
import SavedIcon from '../../assets/icons/checkmark-settings_green.svg'
import { getItemFromLocal, readableServerError, setItemToLocal } from '../../utils/utilFnx';
import SnackBar from 'my-react-snackbar';
import HashLoader from "react-spinners/HashLoader";
import { updateUserBasicAccountSettings } from '../../utils/api/accountApi';




class Account extends React.Component {

    constructor(props) {
        super();
        this.state = {
            user: {
                username: getItemFromLocal("username"),
                first_name: getItemFromLocal("f_name"),
                last_name: getItemFromLocal("l_name"),
                email: getItemFromLocal("email"),
            },
            saveChanges: false,
            loading: false,
            snackMessage: { type: null, message: null },
        }
    }

    handleChange = (e) => {
        this.setState({
            user: { ...this.state.user, [e.target.name]: e.target.value, },
            saveChanges: true,
        });
    }

    onSaveChanges = () => {

        this.setState({
            loading: true,
            snackMessage: { type: null, message: null },
        })
        updateUserBasicAccountSettings(this.state.user).then((data) => {
            console.log(data);
            setItemToLocal("email", this.state.user.email || getItemFromLocal("email"))
            setItemToLocal("f_name", data.first_name)
            setItemToLocal("l_name", data.last_name)
            setItemToLocal("username", data.username)
            this.setState({
                loading: false,
                snackMessage: { type: "success", message: "Account details successfully updated." },

            })

        }).catch((err) => {
            this.setState({
                loading: false,
                snackMessage: { type: "error", message: readableServerError(err) },

            })
        })

    }

    render = () => {
        return (

            <AccountContainer>
                <SnackBar
                    open={this.state.snackMessage.message}
                    message={this.state.snackMessage.message}
                    type={this.state.snackMessage.type}
                    position="bottom-right"
                    timeout={5000}
                />

                <Card margin="1em 30px" >
                    <HighlightedText color="#923D41" size="25px" margin="10px 35px">
                        Account Information
                    </HighlightedText>
                </Card>


                <Card display="flex">

                    <Card margin="10px 30px" width="50%">
                        <HighlightedText color="black" size="20px" margin="10px 35px">
                            First Name
                        </HighlightedText>
                        <Input
                            width="80%"
                            margin="10px 35px"
                            placeholder="Input first name (and middle name if any)"
                            onChange={this.handleChange}
                            name="first_name"
                            value={this.state.user.first_name}
                        />
                    </Card>
                    <Card margin="10px 30px" width="50%">
                        <HighlightedText color="black" size="20px" margin="10px 35px">
                            Last Name
                        </HighlightedText>
                        <Input
                            width="80%"
                            margin="10px 35px"
                            placeholder="Input last name"
                            onChange={this.handleChange}
                            name="last_name"
                            value={this.state.user.last_name}
                        />
                    </Card>

                </Card>

                <Card margin="10px 30px">
                    <HighlightedText color="black" size="20px" margin="10px 35px">
                        Username
                    </HighlightedText>
                    <Input
                        width="30%"
                        margin="10px 35px"
                        placeholder="Input username"
                        onChange={this.handleChange}
                        name="username"
                        value={this.state.user.username} />
                    <HighlightedText color="#923D41" size="9px" margin="10px 38px">
                        Username is unique name used to identify your account other than your legal name.
                    </HighlightedText>
                </Card>
                <Card margin="10px 30px">
                    <HighlightedText color="black" size="20px" margin="10px 35px">
                        Your email address
                    </HighlightedText>
                    <Input
                        width="30%"
                        margin="10px 35px"
                        type="email"
                        placeholder="Input account email"
                        onChange={this.handleChange}
                        name="email"
                        value={this.state.user.email}
                    />
                    <HighlightedText color="#923D41" size="9px" margin="10px 38px" width="40%">
                        This is the email address used to identify the account. It is another communication channel for this platform. Login to your account with the email you input here.
                    </HighlightedText>
                </Card>



                <Card margin="2em 75px">
                    {this.state.loading ?
                        <HashLoader color="#f03434" loading={this.state.loading} size={30} />
                        :
                        <Button
                            height="3em"
                            padding="5px 20px"
                            background={this.state.saveChanges ? "#39903F" : "rgba(232, 232, 232, 1)"}
                            color={this.state.saveChanges ? "#fff" : "rgba(46, 49, 49, 1)"}
                            margin="1em"
                            onClick={this.onSaveChanges}
                            disabled={this.state.loading}
                        >
                            {this.state.saveChanges ? "Save Changes" : "Saved!"}
                            {!this.state.saveChanges &&
                                <img
                                    src={SavedIcon}
                                    width="15px"
                                    height="15px"
                                    alt="savedIcon"
                                />}
                        </Button>
                    }

                </Card>

            </AccountContainer>

        )
    }
}


export default Account


const AccountContainer = styled.div`

    padding: 1em 1em 3em;
    margin: 1em;
    background-color: white;

    button{
        img{
            margin: 0px 0px 0px 10px;
        }
    }

    @media only screen and (max-width: 768px){
        display: block;
        margin: 5% 0%;
        width: auto;
        h1{
            width: 90%;
            margin: 2%;
        }
        
        input{
            width: auto;
            margin: 5% 0;
        }

        div{
            display: block;
        }

    }
`