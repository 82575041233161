import './App.css'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import Authorized from './layouts/authorized'
import UnAuthorized from './layouts/unAuthorized'
import React from 'react'

export const AuthContext = React.createContext({
  sighted: null,
  toggleSighted: () => { },
  speechControlsFunction: () => { },
  speechControlsCallBackFunction: () => { },
})







class App extends React.Component {

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path='/auth' component={UnAuthorized} />
          <Route path='/' component={Authorized} />
          <Redirect to="/" exact />
        </Switch>
      </BrowserRouter>
    )
  }

}






export default App
