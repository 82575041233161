import React from 'react';
import { Card } from '../../components/card';
import { HighlightedText } from '../../components/highlightedText';
import { InputLabel } from '../../components/uiComponents/InputLabel';
import { TextArea } from "../../components/input";
import AddItemIcon from '../../assets/icons/arrow_right_red.svg'
import { Button } from '../../components/uiComponents/button';
import DeleteIcon from '../../assets/icons/trash-can_red.svg'
import { Collapse } from 'react-collapse';
import AnswerCard from './answerCard';


class Question extends React.Component {
    constructor(props) {
        super();
        this.state = {
            collapse: false,
        }
    }

    onAddPossibleAnswer = (e) => {
        this.props.onEdit(this.props.index, { possible_answers: [...this.props.data.possible_answers, {}] })
    }

    onEditPossibleAnswer = (id, data, command) => {
        let allPossibleAnswers = this.props.data.possible_answers
        let pa = allPossibleAnswers[id]
        if (command === "delete") {
            allPossibleAnswers.splice(id, 1);

        } else {

            pa = { ...pa, ...data }
            allPossibleAnswers[id] = pa
        }
        this.props.onEdit(this.props.index, { possible_answers: allPossibleAnswers })

    }

    render = () => {
        return (


            <Card margin="1em 0" padding="1em">
                <Card display="flex"
                    color="#923D41"
                    border="2px dotted #923D41"
                    padding="0.5em"
                    margin="1em 1em 1em 0"
                    alignItems="center"
                    space="space-between">
                    <Button
                        background="transparent"
                        padding="0"
                        display="flex"
                        alignItems="center"
                        onClick={() => this.setState({ collapse: !this.state.collapse })}
                    >

                        <img src={AddItemIcon} width="30px" alt="add icon" />
                        {this.state.collapse && <HighlightedText color="grey" size="16px" margin="0 10px">
                            Question 1
                        </HighlightedText>}
                    </Button>

                    {this.state.collapse ?
                        <> <HighlightedText color="grey" size="16px">
                            {this.props.data.genre}
                        </HighlightedText>
                            <HighlightedText color="grey" size="16px">
                                {this.props.data.points} points
                    </HighlightedText>
                        </>
                        :

                        <><label>

                            <select
                                name="number"
                                value={this.props.data.number}
                                onChange={(e) => this.props.onEdit(this.props.index, { [e.target.name]: e.target.value })}>
                                {
                                    [...Array(this.props.length).keys()].map((questionNumber) => {
                                        return <option key={questionNumber} value={questionNumber}>Question {questionNumber + 1}</option>
                                    })
                                }
                            </select>
                        </label>
                            <Card padding="0em">
                                <label>
                                    Question Type:
                                    <select
                                        name="genre"
                                        value={this.props.data.genre}
                                        onChange={(e) => this.props.onEdit(this.props.index, { [e.target.name]: e.target.value })}>
                                        <option value="multiple-choice">Multiple Choice</option>
                                        <option value="short-answer">Short Phrase Answer</option>
                                        <option value="essay">Essay type</option>
                                    </select>
                                </label>
                                <label>
                                    Points:
                                    <select
                                        name="points"
                                        value={this.props.data.points}
                                        onChange={(e) => this.props.onEdit(this.props.index, { [e.target.name]: e.target.value })}>
                                        {
                                            [...Array(11).keys()].map((points) => {
                                                return <option key={points} value={points}> {points}</option>
                                            })
                                        }
                                    </select>
                                </label>
                                <Button
                                    background="#f2f1ef"
                                    padding="5px"
                                    onClick={() => this.props.onEdit(this.props.index, {}, "delete")}
                                >
                                    <img src={DeleteIcon} width="15px" height="15px" alt="delete icon"/>
                                </Button>
                            </Card>
                        </>
                    }
                </Card>

                <Collapse isOpened={!this.state.collapse}>
                    <HighlightedText size="16px" weight="normal" lineHeight="1.5" margin="0.5em">
                        <TextArea
                            name="question"
                            placeholder="Type question here"
                            width="95%"
                            value={this.props.data.question ?? ""}
                            onChange={(e) => this.props.onEdit(this.props.index, { [e.target.name]: e.target.value })}
                        >
                        </TextArea>
                    </HighlightedText>
                    {this.props.data.genre === "essay" ?
                        <Card background="rgba(190, 144, 212,0.5)" color="rgba(142, 68, 173, 1)" padding="1em" margin="1em">
                            <b>Note that</b>, currently, the platform does not support grading of essay type questions.
                             Hence we kindly recommend that you use this type of question for taking answers that do not need to be graded. Thank you.
                        </Card>
                        :
                        <> {this.props.data.possible_answers?.map((pa, i) =>
                            <AnswerCard
                                key={i}
                                index={i}
                                data={pa}
                                onEdit={this.onEditPossibleAnswer}
                                genre={this.props.data.genre}
                            />)
                        }

                            <Button
                                size="160px"
                                weight="500"
                                color="#2e3131"
                                lineHeight="1.5"
                                background="#e8e8e8"
                                padding="10px 15px"
                                radius="5px"
                                margin="0 0 0 1em"
                                onClick={this.onAddPossibleAnswer}
                            >
                                Add a possible answer
                            </Button>



                            <Card
                                alignItems="center"
                                display="flex">

                                <TextArea
                                    margin="2em"
                                    name="right_answer_explanation"
                                    placeholder="Explanation to the answer"
                                    value={this.props.data.right_answer_explanation ?? ""}

                                    onChange={(e) => this.props.onEdit(this.props.index, { [e.target.name]: e.target.value })}
                                />
                                {this.props.data.genre === "short-answer" && <InputLabel width="30%">
                                    <input
                                        type="checkbox"
                                        checked={this.props.data.exact || false}
                                        name="exact"
                                        onChange={(e) => this.props.onEdit(this.props.index, { [e.target.name]: e.target.checked })}
                                    />
                            Exact
                    </InputLabel>}

                            </Card>

                        </>
                    }
                </Collapse>
            </Card>


        )
    }
}

export default Question;

