export const appendItemToLocal = (name, data) => {
    let allData = getItemFromLocal(name, [])
    allData.push(data)
    localStorage.setItem(name, JSON.stringify(allData));
}

export const sliceItemFromLocal = (name, data) => {
    let allData = getItemFromLocal(name, [])
    
    data = allData.filter(item => !(item === data))
    localStorage.setItem(name, JSON.stringify(data));
}

export const getItemFromLocal = (name, defaultValue) => {
    return localStorage.getItem(name) ? JSON.parse(localStorage.getItem(name)) : defaultValue ?? null;
}