import { packageFormDataSubmission } from '../utilFnx';
import APIClient from './httpClient';

export const isLoggedIn = async () => {
    const account = new APIClient();
    const endpoint = '/check/logged-in/';
    const { data } = await account.accountAuthApi.get(endpoint);
    // console.log(data);
    return data;

}

export const login = async (email, password) => {
    const account = new APIClient();
    const endpoint = '/login/';
    const { data } = await account.accountApi.post(endpoint, { email, password });
    return data
}

export const userLogout = async () => {
    const account = new APIClient();
    const endpoint = '/logout/';
    const { data } = await account.accountAuthApi.post(endpoint);
    return data;

}



export const registerUser = async (registerData) => {
   
    const account = new APIClient();

    const endpoint = '/register/';

    const { data } = await account.accountApi.post(endpoint, registerData);

    return data
}



export const getAdminStudents = async () => {

    const account = new APIClient();
    const endpoint = '/students/';

    try {
        const { data } = await account.accountAuthApi.get(endpoint);
        return data;

    } catch (error) {
        return []
    }
}


export const retrieveMyProfile = async () => {

    const account = new APIClient();
    const endpoint = '/user/profile/me/';

    const { data } = await account.accountAuthApi.get(endpoint);
    return data;
}

export const updateUserBasicAccountSettings = async (userData) => {

    const account = new APIClient();
    const endpoint = '/update/account/';

    const { data } = await account.accountAuthApi.put(endpoint, userData);
    return data;
}

export const updateUserAccountProfile = async (profileData) => {

    const account = new APIClient();
    const endpoint = '/user/profile/update/';
    if (profileData.profilePictureData) {
        console.log("profileData");
        profileData = packageFormDataSubmission(profileData, "profilePictureData", "profile_picture")
        const { data } = await account.accountAuthApi.put(endpoint, profileData);
        return data;

    } else {
        delete profileData.profile_picture

        const { data } = await account.accountAuthApi.put(endpoint, profileData);
        return data;
    }

}



export const updatePasswordData = async (passwordData) => {

    const account = new APIClient();
    const endpoint = '/update/change_password/';

    passwordData.extra_data = passwordData.confirm_new_password // new password field according to backend

    const { data } = await account.accountAuthApi.put(endpoint, passwordData);
    return data;


}


export const forgottenPassword = async (resetData) =>{
    const account = new APIClient();
    const endpoint = '/forgot_password/';
    const { data } = await account.accountApi.post(endpoint, resetData);
    return data;
}



export const activateAccount = async (activationData) => {
   
    const account = new APIClient();

    const endpoint = '/activate/';

    const { data } = await account.accountApi.post(endpoint, activationData);

    return data
}

export const resetPassword = async (resetData) =>{
    const account = new APIClient();
    const endpoint = '/reset/';
    const { data } = await account.accountApi.post(endpoint, resetData);
    return data;
}

