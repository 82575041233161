import React from 'react';
import { Card } from "../../components/card";
import { HighlightedText } from '../../components/highlightedText';
import PublishedIcon from '../../assets/icons/checkmark_green.svg'
import UnPublishedIcon from '../../assets/icons/minus_red.svg'
import { durationReadableFromNumber, timeSince } from '../../utils/utilFnx';
import { quizzesOverviewAnalytics } from '../../utils/api/analyticsApi';
import PulseLoader from "react-spinners/PulseLoader";
import { css } from "@emotion/react";
import QuizIcon from '../../assets/icons/quiz_list.svg';
import { LogoImg } from "../uiComponents/uiElements";
import AnalyticsGraph from "./analyticsGraph";
import SingleAnalytics from "./singleAnalytics";
import UnavailableAnalytics from './unavailableAnalytics';


const graphColors = ["#cf000f", "#8e44ad", "#a537fd", "#663399", "#e87e04",]

class QuizAnalytics extends React.Component {

    constructor(props) {
        super();

        this.state = {
            data: [],
            loading: false
        }
    }

    componentDidMount = () => {
        this.getVideoOverViewAnalyticsData()
    }
    getVideoOverViewAnalyticsData = () => {
        this.setState({
            loading: true
        })
        quizzesOverviewAnalytics().then((data) => {
            this.setState({
                loading: false,
                data: data
            })
        }).catch((err) => {
            throw err
        })
    }


    render = () => {

        if (this.state.loading) {
            return <PulseLoader css={LoadingAnimationStyle} color="#923D41" />
        }
        else {
            if (this.state.data.length) {
                return this.state.data.map((quiz, i) =>
                    <Card key={i} display="flex" margin="1em" padding="1em" alignItems="center">
                        <LogoImg src={QuizIcon}
                            width="75px" height="75px" alt="quiz" radius="5px" />
                        <Card margin="1em" width="30%">

                            <HighlightedText weight="600" color="#24252a" size="">
                                {quiz.title}
                                {quiz.published ?
                                    <img src={PublishedIcon} width="15px" alt="published" style={{ marginLeft: 10 }} />
                                    :
                                    <img src={UnPublishedIcon} width="15px" alt="unpublished" style={{ marginLeft: 10 }} />
                                }
                            </HighlightedText>
                            <HighlightedText weight="500" color="grey" size="13px">
                                Duration: {durationReadableFromNumber(quiz.duration)}
                                <br />
                                Questions per quiz: {quiz.questions_per_quiz || "All questions"}
                                <br />
                                Pass mark: {quiz.pass_mark}
                            </HighlightedText>
                            <HighlightedText weight="600" color="grey" size="15px" margin="0.25em 0 0">
                                Created: {timeSince(quiz.created_datetime)}
                            </HighlightedText>
                            <HighlightedText weight="500" color="grey" size="13px">
                                Updated: {new Date(quiz.updated_datetime).toLocaleString()}
                            </HighlightedText>
                        </Card>

                        <SingleAnalytics singles={quiz.analytics_data.singles} />

                        <Card width="30%" height="150px">
                            <AnalyticsGraph
                                graphColors={graphColors}
                                dataSeries={quiz.analytics_data.data_series}
                                dataSeriesMeta={quiz.analytics_data.data_series_meta}
                            />
                        </Card>
                    </Card>)
            } else {
                return <UnavailableAnalytics content="Quizzes" />
            }
        }
    }
}

export default QuizAnalytics

const LoadingAnimationStyle = css`
  text-align: center;
  margin: 15vh auto;
  display: block;
`;
