import APIClient from './httpClient';

export const noteCreateUpdate = async (noteData) => {
    /* create or update a note
     */
    const api = new APIClient()
    if (noteData.id) {
        const endpoint = `/${noteData.id}/`;
        const { data } = await api.NoteApi.put(endpoint, noteData);
        return data;

    } else {
        const endpoint = "/";
        const { data } = await api.NoteApi.post(endpoint, noteData);
        return data;
    }

}