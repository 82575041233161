import React, { Component } from 'react';
import styled from 'styled-components';
import AdminSideBar from '../components/adminSideBar'
import TopBar from '../components/topBar'
import { Switch, Route, Redirect } from 'react-router-dom';
import { isLoggedIn } from '../utils/api/accountApi'
import ProfileSettingsPage from '../pages/authorized/settings'
import TopicCreateEdit from '../pages/authorized/topicLab'
import TopicList from '../pages/authorized/topicsList'
import QuestionsCreateEdit from '../pages/authorized/quizLab'
import QuizList from '../pages/authorized/quizList'
import Analytics from '../pages/authorized/analytics'
import FeedBack from '../pages/authorized/feedback'


class Authorized extends Component {

    componentDidMount = () => {
        this.loggedIn()
    }

    loggedIn = () => {
        isLoggedIn().then(data => {
            if (!data.response) {
                this.props.history.push("/auth/login");
            }

        }).catch((e) => {
            this.props.history.push("/auth/login");

            // console.log(e.response);
            console.log(
                "You're not logged in.😠"
            );
        });
    }


    render() {
        // console.log(`${this.props.match.path}`); // debug statement for checking what route is being called

        return (
            <UserContainer>

                {/* makes it only appear for big screens only */}
                {/* on smaller screens, check TopBar class */}
                <AdminSideBar className="resp__bo" />
                <UserBody>
                    <TopBar />
                    {
                        <Switch>
                            {/* TODO: dashboard feature would be coming in later */}
                            {/* <Route exact path={`${this.props.match.path}home`} component={Analytics} />  */}
                            {/* <Route exact path={`${this.props.match.path}subscribers`} component={Subscribers} /> */}
                            <Route path={`${this.props.match.path}analytics`} component={Analytics} />
                            <Route path={`${this.props.match.path}settings`} component={ProfileSettingsPage} />
                            <Route exact path={`${this.props.match.path}topic/create`} component={TopicCreateEdit} />
                            <Route exact path={`${this.props.match.path}topic/list`} component={TopicList} />
                            <Route exact path={`${this.props.match.path}topic/edit/:topicID`} component={TopicCreateEdit} />
                            <Route exact path={`${this.props.match.path}quizzes`} component={QuizList} />
                            <Route exact path={`${this.props.match.path}quiz/lab`} component={QuestionsCreateEdit} />
                            <Route exact path={`${this.props.match.path}quiz/lab/:quizID`} component={QuestionsCreateEdit} />
                            <Route exact path={`${this.props.match.path}feedback`} component={FeedBack} />
                            <Redirect to={{ pathname: "/topic/list" }} />

                        </Switch>
                    }
                </UserBody>
            </UserContainer>

        )
    }
}

export default Authorized;



const UserContainer = styled.div`
    width:100%;
    display:flex;
    position: fixed;
    top: 0;
    bottom:0;

`
const UserBody = styled.div`
    background-color: #F7F9FB;
    min-width: 85%;
    min-height: 90vh;
    overflow: scroll;
    overflow-y:scroll;
    overflow-x:hidden;
    padding-bottom: 7vh;

    @media only screen and (max-width: 768px){
        width: 100%;
    }

`
