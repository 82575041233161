import APIClient from './httpClient';

export const postFeedback = async (feedback) => {
    /* post feedback
     */
    const api = new APIClient();
    const endpoint = '/feedback/';

    const { data } = await api.DialogueApi.post(endpoint, feedback);
    return data;

}
