import React, { Component } from 'react';
import styled from 'styled-components';
import UserIcon from '../assets/icons/profile-user.svg'
// import SearchIcon from '../assets/icons/search_white.svg'
import { Button } from './uiComponents/button'
import { userLogout } from '../utils/api/accountApi'
import baseUrl from '../utils/api/httpClient'
import { deleteAuthToken, getAuthToken, getFirstName, getLastName } from '../utils/storage/auth'
// import { Input } from './input';
import { LogoImg } from './uiComponents/uiElements';
import MenuIcon from "../assets/icons/menu1.svg";
import AdminSideBar from './adminSideBar';
// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'


class TopBar extends Component {

    constructor(props) {
        super(props)

        this.state = {
            logout_option: false,
            isOpen: false,
        }
    }


    handleClick = (e) => {

        this.setState(prevState => ({
            logout_option: !prevState.logout_option
        }));
    }

    handleLogout = (e) => {

        userLogout().then(data => {
            deleteAuthToken();
            localStorage.clear()
            window.location.reload();
        }).catch(() => {
            window.location.href = baseUrl;
            deleteAuthToken();
        })
    }

    render() {
        return (
            <TopBarContainer>

                <div className="resp__so">
                    <Drawer
                        open={this.state.isOpen}
                        onClose={() => this.setState({ isOpen: !this.state.isOpen })}
                        onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                        direction='left'>
                        <AdminSideBar onClick={() => this.setState({ isOpen: !this.state.isOpen })}/>
                    </Drawer>
                    <Button
                        background="transparent"
                        padding="0 10px"

                        onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                    >

                        <img src={MenuIcon} alt="menu icon" width="20px" />
                    </Button>
                </div>

                <form>
                    {/* <Input type="text"
                        placeholder="Search"
                        margin="0"
                        padding="0.3em 1em"
                        size="15px"
                        width="40%"
                        radius="5px  0 0 5px"
                        weight="500"
                        border="1px solid grey"
                        color="#923D41"
                    />
                    <Button radius="0 5px 5px 0" height="34px" padding="0 20px">

                        <img src={SearchIcon} width="15px" alt="Search" />
                    </Button> */}
                </form>


                {getAuthToken() !== null ? <div className="auth_container" onClick={this.state.logout_option ? null : this.handleClick}>


                    <LogoImg src={UserIcon} margin="auto 0.75em" width="40px" height="30px" alt="Picture" radius="50%"/>

                    {this.state.logout_option ?
                        <><Button onClick={this.handleClick}
                            className="btn-group">Cancel</Button>
                            <Button onClick={this.handleLogout}
                                className="logout"
                            > Log out</Button>
                        </>
                        : <span className="resp__bo">
                            {`${getFirstName()} ${getLastName()}`}
                        </span>}

                </div> : <div className="unauth_container">
                    <a className="login" href="/auth/login">
                        Login
                    </a>
                    <a className="signup" href="/auth/register">
                        Register
                    </a>
                </div>}

            </TopBarContainer>
        )
    }
}

export default TopBar



const TopBarContainer = styled.div`
    width: 100%;
    height: 50px;
    box-shadow: 0px 1px 6px #00000029;
    justify-content: space-between;
    display: flex;

    .resp__so{
        align-self: center;
    }


    form{
        align-items: center;
        display: flex;
        margin-left: 2em;
        width: 50%;

        input{
            &:hover{
                border :1px solid rgba(120,120,120, 0.5);
                box-shadow: inset 0 0 0 2px rgba(120,120,120, 0.5);
            }

            &:focus {
                outline: none !important;
                box-shadow: inset 0 0 0 2px rgba(146, 61, 65, 0.5);
                border: 1px solid #923D41;
            }
            
        }

    }

    .auth_container{
        display: flex;
        margin: 10px 2em;
        cursor:pointer;


        span{
            align-self: center;
        }

    }

    .unauth_container{
        margin: 0 1em;
        align-self: center;
        display: flex;

        .login{
            text-decoration: none;
            padding: 7px 15px;
            color: rgba(31, 58, 147, 1);
            background: rgba(137, 196, 244, 0.7);
            margin: 0 1em;
            display: block;
            border-radius: 1em;
        }
        .signup{
            text-decoration: none;

            padding: 7px 15px;
            color: rgba(30, 130, 76, 1);
            background: rgba(0, 230, 64, 0.3);
            /* margin-top: 19em; */
            display: block;
            border-radius: 1em;
        }
    }

    .btn-group{           
            padding: 0px 40px;
            margin: 0px 10px;
        }

    .logout{
            background:#ffffff;
            color: red;
            border: 2px solid green;
            padding: 0px 36px;
            margin: 0px 10px;
        }


    @media only screen and (max-width: 768px){
        /* responsiveness code has to be at the bottom so it overrides any conflicting above css  */

        width: 100%;
        height: 45px;
        box-shadow: 0px 3px 6px #00000029;
      
        .auth_container{
            
            display: flex;
            margin: 5px 0em;
            cursor:pointer;


            span{
                align-self: center;
            }
    
        }
    
        input{
            padding: 1em 1em;
            height: -webkit-fill-available;
            border: none;
            font-size: 12px;
            width: 80%;
    
        }
    
        form{
            margin-left: 1em;
            width: 50%;
            float: left;
    
        }

          
    }
`
/*

*/


