import React from 'react';
import styled from 'styled-components';
import VideoIcon from '../../assets/icons/video_wine.svg';
import { uploadVideoToCloudFlare, videoCreateUpdate } from '../../utils/api/videoApi';
import { appendItemToLocal } from '../../utils/functions/storage';
import { convertDurationStringToSeconds } from '../../utils/utilFnx';
import { Card } from '../card';
import { Input, TextArea } from '../input';
import { PassButton } from "../uiComponents/button";


class VideoModal extends React.Component {

    constructor(props) {
        super();
        this.state = {
            title: "",
            description: "",
            video: null,
            uploadReady: false,
        }
    }

    onTextChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }


    handleVideo = (e) => {
        // console.log(e.target.files[0]);
        // console.log(e.target.result);
        var video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = this.onLoadedVideoMetadata

        video.src = URL.createObjectURL(e.target.files[0]);
        this.setState({
            video_file: e.target.files[0],
            title: this.state.title || e.target.files[0].name,
        })
        // since there is no video preview, this part goes away, video file is just saved
    }

    onLoadedVideoMetadata = (e) => {
        // console.log(e.target.duration);
        window.URL.revokeObjectURL(e.target.src);
        this.setState({
            duration: e.target.duration?.toFixed(10),
            video_url: e.target.src,
            uploadReady: true
        })
    }
    handleSubmitVideo = (e) => {
        if (this.state.video_file) {
            this.props.onUploadPercentageChange("Preparing ...")
            uploadVideoToCloudFlare(this.state,
                this.tusUploadOnProgress,
                this.tusUploadOnSuccess,
                this.tusUploadOnAfterResponse,
                this.tusUploadOnError)

        } else {
            this.backendUploadVideo()
        }


        this.props.onSubmitVideo(this.props.index, { content: this.state, modal: false })
    }

    backendUploadVideo = () => {
        videoCreateUpdate(this.state).then((data) => {
            // console.log(data);
            appendItemToLocal("unconfirmedVideos", data.id)
            this.setState({
                ...this.state, ...data
            })

        }).catch((error) => {
            console.log(error.response);
        })
            .finally(() => {
                // TODO: check if this is necessary
                this.props.onSubmitVideo(this.props.index, { content: this.state.id, contentData: this.state, modal: false })
            })
    }

    tusUploadOnProgress = (bytesUploaded, bytesTotal) => {
        var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2);
        this.props.onUploadPercentageChange(`${percentage}%`)
        // console.log(bytesUploaded, bytesTotal, percentage + "%");
    }
    tusUploadOnSuccess = () => {
        // console.log("Upload finished");
        this.props.onUploadPercentageChange("Done uploading")
    }

    tusUploadOnAfterResponse = (req, res) => {
        return new Promise(resolve => {
            var mediaIdHeader = res.getHeader("stream-media-id");
            if (mediaIdHeader) {
                this.setState({
                    video: mediaIdHeader
                })
                // console.log(this.state);
                this.backendUploadVideo()
            }
            resolve()
        })
    }

    tusUploadOnError = (error) => {
        // console.log("Video upload was interrupted", error);
        this.props.onUploadPercentageChange("Error uploading.")
        // throw error;

    }




render() {
    // console.log(this.props);
    return (
        <ModalContainter
            onClick={e => { e.stopPropagation() }}
            width="100%"
            bottom="0"
            right="0"
            top="0"
            left="0"
            radius="0"
            display={this.props.show ? "block" : "none"}
        >

            <div className="modalBody">
                <div className="container">
                    <div className="leftPane">
                        {

                            // TODO: work on video preview
                            <img src={VideoIcon} alt="Video upload" />}
                        {this.state.duration && `Duration: ${convertDurationStringToSeconds(this.state.duration)} secs`}
                    </div>
                    <div className="rightPane">
                        <div className="formTop">
                            <h3 className="title">Upload video content</h3>
                            <p>
                                To upload a video, first click upload to upload a file and fill in the other details.
                            </p>
                        </div>

                        <Input
                            placeholder="Title of video"
                            name="title"
                            value={this.state.title || this.props.data.title || ""}
                            onChange={this.onTextChange} />
                        <TextArea
                            rows={4}
                            display="table"
                            weight="normal"
                            width="80%"
                            height="100px"
                            resize="vertical"
                            placeholder="Extra comments to describe the video. Also, you may keywords that are very descriptive and intuitive for searches."
                            name="description"
                            value={this.state.description || this.props.data.description || ""}
                            onChange={this.onTextChange}
                        >

                        </TextArea>
                        <Card
                            background={(this.state.video || this.state.video_url) ? "rgba(41, 241, 195, 0.2)" : "rgba(224, 130, 131, 0.2)"}
                            color="#A93B3F"
                            padding="1em"
                            border="1px dashed #A93B3F"
                            radius="10px"
                            margin="1em">
                            <Input type="file" placeholder="upload video" onChange={this.handleVideo} />
                        </Card>


                        <div
                            className="buttonContainer">
                            <PassButton
                                color="#535353"
                                background="#ffffff"
                                border="1px solid #535353"
                                onClick={this.props.handleClose}
                            >
                                {this.props.primaryButton || 'Cancel'}
                            </PassButton>

                            <PassButton
                                disabled={!this.state.uploadReady}
                                onClick={this.handleSubmitVideo}
                                color={this.state.uploadReady ? "#fff" : "#535353"}
                                background={this.state.uploadReady ? "#535353" : "#e8e8e8"}>
                                {'Done'}
                            </PassButton>

                        </div>
                    </div>
                </div>

            </div>

        </ModalContainter>
    );
}
}


export default VideoModal





const ModalContainter = styled.div`
    width: ${({ width }) => width || "100%"};
    position:fixed;
    background: ${({ background }) => background || "rgba(0, 0, 0, 0.6)"};
    color: ${({ color }) => color || "#ffffff"};
    border-radius: ${({ radius }) => radius || "0px"};
    height: auto;
    top: ${({ top }) => top};
    left: ${({ left }) => left};
    bottom: ${({ bottom }) => bottom};
    right: ${({ right }) => right};
    transform: ${({ transform }) => transform};
    z-index: 1;
    display: ${({ display }) => display} !important; // make modal work in responsivenss


    .modalBody{

        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        margin: 0 50px;

        .container {
            display: flex;
            width: 80%;
            height: 70%;

        }

        .leftPane{
            background-color: #fff;
            width: 50%;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            padding: 20px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: black;

            img {
                height: 175px;
            }
        }

        .rightPane{
            background-color: #F7F7F7;
            width: 100%;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            box-sizing: border-box;
            padding: 0px 8%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            overflow: hidden scroll;


            .buttonContainer{
                margin: 20px;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;  
            }

            .formTop {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                margin-bottom: 30px;

                input {
                    margin-top: 20px;
                    border-bottom: 1px solid  #A93B3F;
                    color: #A93B3F;
                    margin-bottom: 10px;
                    width: 100%;
                }

                input::placeholder{
                    color: #A93B3F;
                }
            }

            .title {
                color: #535353;
                font-size: 32px;
                margin-bottom: 0;
            }
            p{
                color: #767676;
                width: 90%;
                margin-right: 10%;
                font-size: 15px;
                line-height: 1.6;
            }
        }
        

    }
    @media only screen and (max-width: 768px){
        background-color: white !important;
        margin: 0 auto !important;
        div{
            background-color: white !important;
        }

        .modalBody{
            margin: 0 10px;
            .container{
                display: block;
                width: 100%;
                height: 90%;
                margin: 0px auto;
                text-align: -webkit-center;
                overflow: auto;
            }

            .leftPane{
                width: 90%;
                border-bottom-left-radius: 0;
                border-top-right-radius: 15px;

                img {
                    height: 125px;
                }
            }
            .rightPane{
                border-top-right-radius: 0;
                border-bottom-left-radius: 15px;
                width: 90%;
            }
        }
    }

    
`