
import React from 'react';
import styled from 'styled-components';
import { HighlightedText, HightlightedSpan } from '../highlightedText';
import { Card } from "../card";
import ProfilePic from '../../assets/images/topic.svg'
import { Input, TextArea } from "../input";
import { Button } from '../uiComponents/button'
import SavedIcon from '../../assets/icons/checkmark-settings_green.svg'
import CancelIcon from '../../assets/icons/cancel.svg'
import { retrieveMyProfile, updateUserAccountProfile } from '../../utils/api/accountApi';
import { getItemFromLocal, pruneDataObject, readableServerError, setItemToLocal } from '../../utils/utilFnx';
import SnackBar from 'my-react-snackbar';
import HashLoader from "react-spinners/HashLoader";
import { LogoImg } from "../uiComponents/uiElements";



class Profile extends React.Component {
    constructor(props) {
        super();
        this.state = {
            profile: {
                subjects_of_interest: [],
            },
            saveChanges: false,
            loading: false,
            snackMessage: { type: null, message: null },
        }
    }

    componentDidMount = () => {
        if (Boolean(getItemFromLocal("profile"))) {
            const data = getItemFromLocal("profile")
            this.setState({
                profile: { ...data, subjects_of_interest: data.subjects_of_interest || [] }
            })

        } else {
            this.getUserProfileData()

        }
    }

    getUserProfileData = () => {
        this.setState({
            loading: true,
            snackMessage: { type: null, message: null },
        })
        retrieveMyProfile().then((data) => {
            // console.log(data);
            this.setState({
                profile: { ...data, subjects_of_interest: data.subjects_of_interest || [] },
                loading: false,

            })
            setItemToLocal("profile", data)

        }).catch((err) => {
            this.setState({
                loading: false,
                snackMessage: { type: "error", message: readableServerError(err) },
            })
        })
    }


    onVariableChange = (e) => {
        // track variable changes that are not conventional
        this.setState({
            [e.target.name]: e.target.value,
        });
        // console.log(this.state.label);
    }


    onAddDeleteLabel = (e, command) => {
        // TODO: merge this function into `onTopicChange`
        if (command === "delete") {
            const updatedLabels = this.state.profile.subjects_of_interest.filter(item => !(item === e))

            this.setState({
                profile: { ...this.state.profile, subjects_of_interest: updatedLabels }, saveChanges: true,
            })

        } else {



            this.setState({
                // NOTE: the variable for storing labels interrim is called 
                interest: "",
                profile: {
                    ...this.state.profile,
                    subjects_of_interest: [
                        ...this.state.profile.subjects_of_interest,
                        this.state.interest
                    ],
                },
                saveChanges: true,
            })
        }


    }

    handleProfileChange = (e) => {
        this.setState({
            profile: { ...this.state.profile, [e.target.name]: e.target.value },
            saveChanges: true,
        })
        // console.log(this.state.profile);
    }


    handleImages = (e) => {
        this.setState({
            profile: {
                ...this.state.profile,
                profile_picture: URL.createObjectURL(e.target.files[0]),
                profilePictureData: e.target.files[0],
            },
            saveChanges: true,
        })
        // console.log(e.target.files[0]);

    }

    

    onSaveProfileChanges = (e) => {
        this.setState({
            loading: true,
            snackMessage: { type: null, message: null },
        })
        updateUserAccountProfile(pruneDataObject(this.state.profile)).then((data) => {
            this.setState({
                loading: false,
                saveChanges: false,
                profile: { ...data, subjects_of_interest: data.subjects_of_interest || [] },
                snackMessage: { type: "success", message: "Profile details successfully updated." },
            })
            setItemToLocal("profile", data)
        }).catch(err => {
            console.log(err.response);
            this.setState({
                loading: false,
                snackMessage: { type: "error", message: readableServerError(err) },
            })
        })

    }

    render = () => {
        return (
            <ProfileContainer >
                <SnackBar
                    open={this.state.snackMessage.message}
                    message={this.state.snackMessage.message}
                    type={this.state.snackMessage.type}
                    position="bottom-right"
                    timeout={5000}
                />

                <Card margin="1em 30px" >
                    <HighlightedText color="#923D41" size="25px" margin="10px 35px">
                        Profile Information
                    </HighlightedText>
                    <br />
                    <HighlightedText color="black" size="20px" margin="10px 35px">
                        Your Photo
                    </HighlightedText>
                    <LogoImg 
                    src={this.state.profile.profile_picture || ProfilePic} 
                    alt="user" 
                    width="200px" 
                    height="200px" 
                    radius="50%" 
                    background="#00b16a50"
                    />
                </Card>
                <Card margin="10px 75px">
                    <Input type="file" accept="image/*" name="profile_picture" hidden display="none" onChange={this.handleImages} />
                    <Button
                        background="#923D41"
                        width="100px"
                        height="40px"
                        padding="0px 0px"
                        onClick={() => document.getElementsByName("profile_picture")[0].click()}
                    >
                        <span>Browse</span>
                    </Button>
                </Card>
                <br />
                <Card>




                    <Card display="flex">

                        <Card margin="10px 30px" width="50%">
                            <HighlightedText color="black" size="20px" margin="10px 35px">
                                Date of birth
                            </HighlightedText>
                            <Input
                                width="80%"
                                type="date"
                                margin="10px 35px"
                                placeholder="Input date of birth"
                                onChange={this.handleProfileChange}
                                name="date_of_birth"
                                value={this.state.profile.date_of_birth || ""} />

                        </Card>
                        <Card margin="10px 30px" width="50%">
                            <HighlightedText color="black" size="20px" margin="10px 35px">
                                Gender
                            </HighlightedText>
                            <select name="gender" value={this.state.profile.gender ?? "M"} onChange={this.handleProfileChange}>
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                                <option value="O">Other</option>
                            </select>
                        </Card>

                    </Card>

                    <Card margin="10px 30px">
                        <HighlightedText color="black" size="20px" margin="10px 35px">
                            Institution/School
                        </HighlightedText>
                        <Input
                            width="30%"
                            margin="10px 35px"
                            placeholder="Input institution or school"
                            onChange={this.handleProfileChange}
                            name="school"
                            value={this.state.profile.school || ""} />
                        <HighlightedText color="#923D41" size="9px" margin="10px 38px">
                            School or institution you are currently affiliated with.
                        </HighlightedText>
                    </Card>

                    <Card display="flex">

                        <Card margin="10px 30px" width="50%">
                            <HighlightedText color="black" size="20px" margin="10px 35px">
                                Location
                            </HighlightedText>
                            <Input
                                width="80%"
                                margin="10px 35px"
                                placeholder="Main location for using the platform"
                                onChange={this.handleProfileChange}
                                name="location"
                                value={this.state.profile.location || ""}
                            />
                            <HighlightedText color="#923D41" size="9px" margin="10px 38px">
                                Knowing the location in which you are using the platform enables us to know
                                how best to tailer content to you by factoring internet speed, need and popularity.
                            </HighlightedText>
                        </Card>
                        <Card margin="10px 30px" width="50%">
                            <HighlightedText color="black" size="20px" margin="10px 35px">
                                Telephone
                            </HighlightedText>
                            <Input
                                width="80%"
                                type="tel"
                                margin="10px 35px"
                                placeholder="Input last name"
                                onChange={this.handleProfileChange}
                                name="telephone"
                                value={this.state.profile.telephone || ""}
                            />
                            <HighlightedText color="#923D41" size="9px" margin="10px 38px">
                                This telephone number is going to be our secondary contact
                            </HighlightedText>
                        </Card>

                    </Card>




                    <Card margin="10px 30px">
                        <HighlightedText color="black" size="20px" margin="10px 35px">
                            About You
                        </HighlightedText>
                        <TextArea
                            width="40%"
                            margin="10px 35px"
                            name="biography"
                            placeholder="A bit about you. Could be as simple as your background, aspirations and current endeavours"
                            onChange={this.handleProfileChange}
                            value={this.state.profile.biography}
                        />
                    </Card>
                    <Card margin="10px 75px">
                        <HighlightedText color="black" size="20px" >
                            Subjects / Areas of interest
                        </HighlightedText>
                        <Card display="flex" flexFlow="wrap" margin="10px 0">
                            {this.state.profile.subjects_of_interest?.map((interest, i) => {
                                return (

                                    <Card key={i} display="flex">
                                        <HightlightedSpan
                                            background="rgba(232, 236, 241, 1)"
                                            color="rgba(46, 49, 49, 1)"
                                            size="12px"
                                            weight="normal"
                                            padding="5px 5px 5px 10px"
                                            margin="3px"
                                            radius="1em"
                                        >
                                            {interest}
                                            <Button
                                                background="rgba(231, 76, 60, 1)"
                                                padding="2px 5px"
                                                margin="0px 0px 0px 7px"
                                                radius="10em"
                                                onClick={(e) => this.onAddDeleteLabel(interest, "delete")}
                                            >
                                                <img src={CancelIcon} width="8px" alt="cancel icon" />
                                            </Button>
                                        </HightlightedSpan>

                                    </Card>
                                )
                            })
                            }

                        </Card>
                        <Card display="flex">
                            <Input
                                margin="25px 0px"
                                padding="5px 10px"
                                value={this.state.interest || ""}
                                radius="3px 0 0 3px"
                                name="interest"
                                width="40%"
                                placeholder="Click the Add button when you're done"
                                onChange={this.onVariableChange} />

                            <Button
                                color="white"
                                padding="5px 15px"
                                margin="25px 0"
                                radius="0 3px 3px 0"
                                onClick={this.onAddDeleteLabel}>Add</Button>
                        </Card>
                    </Card>




                </Card>


                <Card margin="2em 75px">
                    {this.state.loading ?
                        <HashLoader color="#f03434" loading={this.state.loading} size={30} />
                        :
                        <Button
                            height="3em"
                            padding="5px 20px"
                            background={this.state.saveChanges ? "#39903F" : "rgba(232, 232, 232, 1)"}
                            color={this.state.saveChanges ? "#fff" : "rgba(46, 49, 49, 1)"}
                            margin="1em"
                            onClick={this.onSaveProfileChanges}
                        >
                            {this.state.saveChanges ? "Save Changes" : "Saved!"}
                            {!this.state.saveChanges &&
                                <img
                                    src={SavedIcon}
                                    width="15px"
                                    height="15px"
                                    alt="savedIcon"
                                />}
                        </Button>}
                </Card>


            </ProfileContainer>

        )
    }
}


export default Profile


const ProfileContainer = styled.div`
    padding: 1em;
    margin: 1em;
    background-color: white;

    select{
        width: 80%;
        margin: 0 1em;
        padding: 0.7em 1em;
        font-size: 1em;
    }

        @media only screen and (max-width: 768px){
        display: block;
        margin: 5% 0%;
        width: auto;
        h1{
            width: 90%;
            margin: 2%;
        }
        
        input{
            width: auto;
            margin: 5% 0;
        }
        textarea{
            width: auto;
            margin: 5% 0;
        }
        select{
            width: auto;
            margin: 5% 0;
        }

        div{
            display: block;
            margin: 2px;
        }

    }
`