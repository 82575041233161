import React from 'react';
import { Card } from "../../components/card";
import { HighlightedText } from '../../components/highlightedText';
import PublishedIcon from '../../assets/icons/checkmark_green.svg'
import UnPublishedIcon from '../../assets/icons/minus_red.svg'
import { timeSince } from '../../utils/utilFnx';
import { videosOverviewAnalytics } from '../../utils/api/analyticsApi';
import PulseLoader from "react-spinners/PulseLoader";
import { css } from "@emotion/react";
import VideoIcon from '../../assets/icons/video_wine.svg';
import { LogoImg } from "../uiComponents/uiElements";
import AnalyticsGraph from "./analyticsGraph";
import SingleAnalytics from "./singleAnalytics";
import UnavailableAnalytics from './unavailableAnalytics';


const graphColors = ["#663399", "#e87e04", "#cf000f", "#8e44ad", "#a537fd",]

class VideoAnalytics extends React.Component {

    constructor(props) {
        super();

        this.state = {
            data: [],
            loading: false
        }
    }

    componentDidMount = () => {
        this.getVideoOverViewAnalyticsData()
    }
    getVideoOverViewAnalyticsData = () => {
        this.setState({
            loading: true
        })
        videosOverviewAnalytics().then((data) => {
            this.setState({
                loading: false,
                data: data
            })
        }).catch((err) => {
            throw err
        })
    }


    render = () => {

        if (this.state.loading) {
            return <PulseLoader css={LoadingAnimationStyle} color="#923D41" />
        }
        else {
            if (this.state.data.length) {
                return this.state.data.map((video, i) =>
                    <Card key={i} display="flex" margin="1em" padding="1em" alignItems="center">
                        <LogoImg src={VideoIcon}
                            width="75px" height="75px" alt="video" radius="5px" />
                        <Card margin="1em" width="30%">

                            <HighlightedText weight="600" color="#24252a" size="">
                                {video.title}
                                {video.published ?
                                    <img src={PublishedIcon} width="15px" alt="published" style={{ marginLeft: 10 }} />
                                    :
                                    <img src={UnPublishedIcon} width="15px" alt="unpublished" style={{ marginLeft: 10 }} />
                                }
                            </HighlightedText>
                            <HighlightedText weight="500" color="grey" size="13px">
                                {video.description?.substr(0, 150)}
                            </HighlightedText>
                            <HighlightedText weight="600" color="grey" size="15px" margin="0.25em 0 0">
                                Created: {timeSince(video.created_datetime)}
                            </HighlightedText>
                            <HighlightedText weight="500" color="grey" size="13px">
                                Updated: {new Date(video.updated_datetime).toLocaleString()}
                            </HighlightedText>
                        </Card>

                        <SingleAnalytics singles={[...video.analytics_data.singles, { data: video.likes, presentation: "Likes" }]} />

                        <Card width="30%" height="150px">
                            <AnalyticsGraph
                                graphColors={graphColors}
                                dataSeries={video.analytics_data.data_series}
                                dataSeriesMeta={video.analytics_data.data_series_meta}
                            />
                        </Card>
                    </Card>)

            } else {
                return <UnavailableAnalytics content="Videos" />
            }
        }
    }
}

export default VideoAnalytics

const LoadingAnimationStyle = css`
  text-align: center;
  margin: 15vh auto;
  display: block;
`;
