import React from 'react';
import styled from 'styled-components';
import { HighlightedText } from '../../components/highlightedText';
import { Card } from "../../components/card";
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { durationReadableFromNumber } from '../../utils/utilFnx';
import { overallOverviewAnalytics } from '../../utils/api/analyticsApi';
import TopicAnalytics from '../../components/analytics/topic';
import VideoAnalytics from '../../components/analytics/video';
import QuizAnalytics from '../../components/analytics/quiz';


class Analytics extends React.Component {

  constructor(props) {
    super();

    this.state = {
      data: { overview: [] },
      loading: false
    }

  }

  componentDidMount = () => {
    this.getOverallOverViewAnalyticsdata()
  }

  getOverallOverViewAnalyticsdata = () => {
    this.setState({
      loading: true
    })
    overallOverviewAnalytics().then((data) => {
      this.setState({
        loading: false,
        data: data
      })
    }).catch((err) => {
      throw err
    })
  }

  /* DEBUG: After spending 4 hours, i have come to realise that 
  creating a render function for a class component this way 
  `render =() => {}` creates problems when i later on try to create a sub component in the class, such as: 
  render =()=>{return <this.ChildComponent />} NOTE: breaks on production. 
  IT WELL LEGIT BREAK THE ENTIRE APPLICATION IN PRODUCTION 

  Instead, create the function like this if you plan to do that:
  render (){ return <this.ChildComponent />} this works!
  */

  render() {
    // console.log(this.state);
    return (
      <AnalyticsPageContainer>
        <div className="introHeader">
          <HighlightedText margin="1em" color="#923D41">Analytics
          </HighlightedText>
        </div>

        <HighlightedText margin="1em" color="">Overview </HighlightedText>

        <Card shadow="0px 12px 30px #923D411A" style={{ borderLeft: "3px solid #923D41" }} margin="1em" padding="1em">
          <HighlightedText margin="1em" color="grey" size="18px">Last 28 days {this.state.loading && " (Loading...) "}</HighlightedText>

          <Card space="space-between" display="flex" flexFlow="wrap">
            {
              this.state.data.overview.map((overview, i) => <this.overViewItem key={i} data={overview} />)
            }

          </Card>
        </Card>


        <Card display="flex" margin="2em 1em" className="tab">
          <NavLink
            activeClassName="active"
            to="/analytics/topics">
            <HighlightedText size="15px" textAlign="center" color="#923D41" margin="1em">
              Topics
            </HighlightedText>

          </NavLink>
          <NavLink
            activeClassName="active"
            to="/analytics/videos">
            <HighlightedText size="15px" textAlign="center" color="#923D41" margin="1em">
              Videos
            </HighlightedText>

          </NavLink>
          <NavLink
            activeClassName="active"
            to="/analytics/quizzes">
            <HighlightedText size="15px" textAlign="center" color="#923D41" margin="1em">
              Quizzes
            </HighlightedText>

          </NavLink>
        </Card>

        <Switch>

          <Route exact path={`${this.props.match.path}/topics`} component={TopicAnalytics} />
          <Route exact path={`${this.props.match.path}/videos`} component={VideoAnalytics} />
          <Route exact path={`${this.props.match.path}/quizzes`} component={QuizAnalytics} />
          <Redirect to={{ pathname: "/analytics/topics" }} />

        </Switch>

      </AnalyticsPageContainer>

    );
  }

  overViewItem = (props) => {
    return (
      <Card margin="1em 1em" padding="2em">
        <HighlightedText color="#923D41">
          {props.data.unit === "time"
            ? durationReadableFromNumber(props.data.value) || 0 : props.data.value || 0}</HighlightedText>
        <br />
        <HighlightedText size="1em">{props.data.title}</HighlightedText>
      </Card>
    )
  }
}

export default Analytics


const AnalyticsPageContainer = styled.div`

  a{
        text-decoration: none;
        margin: 10px 20px;
        
        border-radius: 5px;

        &.active{
            background-color: rgba(232, 232, 232, 0.6);
            border-bottom: 3px solid #923D41;
        }
    }

    @media only screen and (max-width: 768px){
        display: block;
        margin: 5% 2%;
        
        h1{
            width: 90%;
            margin: 2%;
        }
        .tab{
            padding: 0 2%;
            margin: 2%;
            align-items: center;
            justify-content: space-between;
            a{
                margin: 0;
                padding: 3% 4%;
            }
        }
        div{
          width: -webkit-fill-available;
          width: -moz-available;
          flex-flow: wrap;
        }
    }

`