import React from 'react';
import { Card } from "../../components/card";
import { HighlightedText } from '../../components/highlightedText';
import PublishedIcon from '../../assets/icons/checkmark_green.svg'
import UnPublishedIcon from '../../assets/icons/minus_red.svg'
import { timeSince } from '../../utils/utilFnx';
import { topicsOverviewAnalytics } from '../../utils/api/analyticsApi';
import PulseLoader from "react-spinners/PulseLoader";
import { css } from "@emotion/react";
import TopicDefaultImg from '../../assets/images/defaultTopic.jpg'
import { LogoImg } from "../uiComponents/uiElements";
import AnalyticsGraph from "./analyticsGraph";
import SingleAnalytics from "./singleAnalytics";
import UnavailableAnalytics from './unavailableAnalytics';

const graphColors = ["#8884d8", "#82ca9d", "#2e3131", "#6c7a89", "#8e44ad"]

class TopicAnalytics extends React.Component {

    constructor(props) {
        super();

        this.state = {
            data: [],
            loading: false
        }
    }

    componentDidMount = () => {
        this.getTopicsOverViewAnalyticsData()
    }
    getTopicsOverViewAnalyticsData = () => {
        this.setState({
            loading: true
        })
        topicsOverviewAnalytics().then((data) => {
            this.setState({
                loading: false,
                data: data
            })
        }).catch((err)=>{
            throw err
        })
    }


    render = () => {
        
        if (this.state.loading) {
            return <PulseLoader css={LoadingAnimationStyle} color="#923D41" />
        }
        else {
            if (this.state.data.length) {
            return this.state.data.map((topic, i) =>
                <Card key={i} display="flex" margin="1em" padding="1em" alignItems="center">
                    <LogoImg src={topic.thumbnail || TopicDefaultImg}
                        width="75px" height="75px" alt="topic" radius="5px" background="#f2f2f3" />
                    <Card margin="1em" width="30%">

                        <HighlightedText weight="600" color="#24252a" size="">
                            {topic.title}
                            {topic.published ?
                                <img src={PublishedIcon} width="15px" alt="published" style={{ marginLeft: 10 }} />
                                :
                                <img src={UnPublishedIcon} width="15px" alt="unpublished" style={{ marginLeft: 10 }} />
                            }
                        </HighlightedText>
                        <HighlightedText weight="500" color="grey" size="13px">
                            {topic.description.substr(0, 150)}
                        </HighlightedText>
                        <HighlightedText weight="600" color="grey" size="15px" margin="0.25em 0 0">
                            Created: {timeSince(topic.created_datetime)}
                        </HighlightedText>
                        <HighlightedText weight="500" color="grey" size="13px">
                            Updated: {new Date(topic.updated_datetime).toLocaleString()}
                        </HighlightedText>
                    </Card>

                    <SingleAnalytics singles={topic.analytics_data.singles}/>

                    <Card width="30%" height="150px">

                    <AnalyticsGraph
                            graphColors={graphColors}
                            dataSeries={topic.analytics_data.data_series}
                            dataSeriesMeta={topic.analytics_data.data_series_meta}
                        />
                    </Card>
                </Card>)
                
            } else {
               return  <UnavailableAnalytics content="Topics"/>
            }

        }
    }
}

export default TopicAnalytics

const LoadingAnimationStyle = css`
  text-align: center;
  margin: 15vh auto;
  display: block;
`;
