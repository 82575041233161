import React from 'react';
import styled from 'styled-components';
import { HighlightedText, HightlightedSpan } from '../../components/highlightedText';
import { Link } from 'react-router-dom'
import { Card } from "../../components/card";
import QuizListIcon from "../../assets/icons/quiz_list.svg";
import DeleteIcon from "../../assets/icons/trash-can.svg";
import EditIcon from "../../assets/icons/pencil.svg";
import DeleteIllustration from "../../assets/illustrations/delete.svg";
import { numberingShortnerReadable } from '../../utils/utilFnx';
import { Button } from '../uiComponents/button';
import AppModal from '../uiComponents/modal';
import { deleteQuiz } from '../../utils/api/quizApi';



class QuizListItem extends React.Component {

    constructor(props) {
        super();
        this.state = {
            deleteModal: false,
        }
    }

    onDeleteQuiz = (id) => {
        deleteQuiz(id).then(data => {
            window.location.reload();
        })
    }

    render() {
        return (

            <QuizListItemContainer>

                <Card display="flex" width="55%" >
                    <img src={QuizListIcon} width="30px" height="5%" alt="quizIcon" />
                    <Card margin="0 0.5em">

                        <HighlightedText size="22px" display="flex" flexFlow="wrap">
                            {this.props.data.title}

                            {this.props.data.published ?
                                <HightlightedSpan
                                    color="rgba(1, 152, 117, 1)"
                                    background="rgba(0, 177, 106, 0.3)"
                                    size="12px"
                                    weight="700"
                                    margin="0em 1em"
                                    padding="5px"
                                    radius="2px"
                                    width="min-content"
                                >
                                    Published
                                </HightlightedSpan>
                                :
                                <HightlightedSpan
                                    color="rgba(207, 0, 15, 1)"
                                    background="rgba(224, 130, 131, 0.3)"
                                    size="12px"
                                    weight="700"
                                    margin="0em 1em"
                                    padding="5px"
                                    radius="2px"
                                    width="min-content"
                                >
                                    UnPublished
                                </HightlightedSpan>
                            }
                        </HighlightedText>
                        <HightlightedSpan color="grey" size="12px">
                            Updated: {new Date(this.props.data.updated_datetime).toDateString()}
                        </HightlightedSpan>
                    </Card>

                </Card>
                <Card margin="0 2em" width="20%" padding="0.5em" style={{ borderLeft: "solid 3px #923D41" }}>
                    <HighlightedText size="20px">
                        Pass: {this.props.data.pass_percent}%
                    </HighlightedText>
                    <HightlightedSpan color="grey" size="12px">
                        Taken by: {numberingShortnerReadable(this.props.data.attempts)}
                    </HightlightedSpan>
                </Card>

                <Card display="flex" background="transparent">
                    <Link to={{
                        pathname: `/quiz/lab/${this.props.data.id}`,
                        state: this.props.data
                    }} className="editIcon" padding="5px" background="rgba(38,166,91,0.1)">
                        <img src={EditIcon} width="20px" height="20px" alt="edit icon" />
                    </Link>
                    <Button
                        padding="5px"
                        width="20%"
                        background="transparent"
                        onClick={() => this.setState({ deleteModal: true })}
                    >
                        <img src={DeleteIcon} width="20px" height="20px" alt="delete icon" />
                    </Button>
                </Card>
                <AppModal
                    show={this.state.deleteModal}
                    image={DeleteIllustration}
                    primary={() => this.onDeleteQuiz(this.props.data.id)}
                    header="Confirm Delete Action"
                    primaryButton="Go ahead!"
                    handleClose={() => this.setState({ deleteModal: false })}
                    message={`Are you sure you want to delete this quiz titled, ${this.props.data.title}? Note that this action cannot be undone. Once you delete the quiz, it is deleted forever.`} />

            </QuizListItemContainer>

        )
    }

}

export default QuizListItem


const QuizListItemContainer = styled.div`
    display: flex;
    padding: 1em;
    margin: 0.5em 1em 1em;
    align-items: center;
    justify-content: space-between;
    border: solid 2px black;
    background-color: white;

    .editIcon{
        padding: 15px;
        background-color: rgba(38,166,91,0.2);
        text-decoration: none;
        border-radius: 0.5em;
    }
    &:hover{
        border-color: #a93b3f;
        box-shadow: 0px 3px 6px #00000029;
    }

    @media only screen and (max-width: 768px) {
        display: block;
        div{
            width: auto;
        }
    }
    
`