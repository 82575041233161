
import React from 'react';
import { Card } from '../../components/card'
import { Button } from '../../components/uiComponents/button'
import styled from 'styled-components';
import { HighlightedText } from '../../components/highlightedText';
import { Input } from '../../components/input';
import { resetPassword } from '../../utils/api/accountApi';
import { readableServerError } from '../../utils/utilFnx';
import ClipLoader from "react-spinners/ClipLoader";


class ResetPassword extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            newPassword: null,
            confirmNewPassword: null,
            resetID: null,
            loading: false,
            errorMessage: null
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
        // console.log(this.state)
    }


    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.newPassword && this.state.newPassword === this.state.confirmNewPassword) {
            this.setState({ loading: true });
            resetPassword({
                password: this.state.newPassword,
                extra_data: this.props.match.params.resetID
            }).then(data => {
                this.setState({ loading: false });

                this.props.history.push('/auth/login')

            }).catch(err => {
                console.log(err.message)
                this.setState({
                    loading: false,
                    errorMessage: readableServerError(err),
                })

            })

        } else {
            this.setState({
                errorMessage: "Password Mismatch"
            })

        }

    }


    render() {

        return (
            <ResetPasswordFormContainer>
                <HighlightedText textAlign="center" color="#923D41">
                    Reset Password
                </HighlightedText>
                <Card shadow="0px 12px 30px #923D411A" padding="2em 1em 1em">
                    <form onSubmit={this.handleSubmit}>
                        <Input placeholder="New password" type="password" name="newPassword" onChange={this.handleChange} required />
                        <Input placeholder="Retype password" type="password" name="confirmNewPassword" onChange={this.handleChange} required />
                        {this.state.errorMessage && <p>{this.state.errorMessage}</p>}
                        <Button background="#923D41" radius="30px" margin="5em auto" width="90%">
                            <ClipLoader color="#fff" loading={this.state.loading} size={10} />
                            {!this.state.loading && <span>RESET</span>}
                        </Button>
                    </form>
                </Card>
            </ResetPasswordFormContainer>

        );
    }
}
export default ResetPassword;


const ResetPasswordFormContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:25%;

    input{
        border: 1px solid black;
        border-radius: 3px;
        display: block;
        padding: 10px 20px;
        margin: 25px auto;
        width: 80%;
        height: 1.5em;

        text-align: left;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        color: #923D41;
    }


    input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #923D41;
        font-weight: normal;
        opacity: 1; /* Firefox */
      }


    button{
        display: block;
        height: 40px;
    }

    a{
        text-decoration: none;
        color: #923D41;
        font-size: 12px;
        text-align: center;
        display: block;
        margin-top: 2em;
    }

    p{
        color: #923D41;
        text-align: center;
        margin: 0 auto;
        background: rgba(146, 61, 65, 0.1);
        width: fit-content;
        padding: 0.5em;
        border-radius: 0.5em
    }

    @media only screen and (max-width: 768px){
        display: block;
        margin: 20px auto;
        width:90%;
    }


`
