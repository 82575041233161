import React from 'react';
import { HighlightedText } from '../highlightedText';
import { Card } from "../card";
import { Button } from '../uiComponents/button';
import DeleteIcon from '../../assets/icons/trash-can_white.svg'
import VideoIcon from '../../assets/icons/video_wine.svg';
import NoteIcon from '../../assets/icons/notes-wine.svg';
import QuizIcon from '../../assets/icons/quiz-paper-filled-wine.svg';
import VideoModal from './videoUploadModal';
import { durationReadableFromNumber } from '../../utils/utilFnx';
import QuizModal from './quizModal';
import NoteModal from './noteModal';

class SyllabusContent extends React.Component {

    render = () => {
        // console.log(this.props.data)
        return (
            <li>
                <Card
                    display="flex"
                    color="#923D41"
                    style={{ borderLeft: "2px solid #923D41" }}
                    padding="0.5em" margin="1em 0"
                    alignItems="center"
                    space="space-between">
                    {this.props.edit ?
                        <Card>
                            <Button
                                size="16px"
                                weight="500"
                                color="#923D41"
                                lineHeight="1.5"
                                background="#e8e8e8"
                                padding="5px 10px"
                                radius="5px"
                                margin="0 0 0 1em"
                                onClick={() => this.props.onEdit(this.props.index, { modal: true })}
                            >
                                {this.props.data.contentData?.title || this.props.data.content?.title || "No title"}
                            </Button>
                            <label>
                                <select name="order" style={{ margin: "10px" }} value={this.props.data.order}
                                    onChange={(e) => this.props.onEdit(this.props.index, { [e.target.name]: e.target.value })}>
                                    <option value={this.props.length - 1}>Order(last)</option>
                                    {
                                        [...Array(this.props.length).keys()].map((orderNumber) => {
                                            return <option key={orderNumber} value={orderNumber}>{orderNumber + 1}</option>
                                        })
                                    }
                                </select>
                            </label>
                        </Card>
                        :
                        <HighlightedText size="16px" weight="500" color="#923D41" lineHeight="1.5">
                            {this.props.data.contentData?.title || this.props.data.content?.title}

                        </HighlightedText>}
                    <Card display="flex" alignItems="center">

                        {
                            this.state?.percentage
                            &&
                            <HighlightedText
                                size="13px"
                                background="rgba(232,232,232,0.5)"
                                weight="600"
                                color="rgba(142,68,173,1)"
                                lineHeight="1.5"
                                margin="0 0.5em"
                                padding="0.5em"
                                radius="10px"
                            >
                                {this.state.percentage}
                            </HighlightedText>
                        }

                        {
                            <img
                                src={this.props.data.content_type === "video" 
                                ? VideoIcon : this.props.data.content_type === "note" ? NoteIcon: QuizIcon}
                                width="18px"
                                height="18px"
                                alt="content type"
                            />
                        }
                        <HighlightedText size="13px" weight="600" color="grey" lineHeight="1.5" margin="0 0.5em">
                            {durationReadableFromNumber(this.props.data.contentData?.duration || this.props.data.content.duration)}
                        </HighlightedText>
                        {this.props.edit && <Button raduis="1em" padding="10px"
                            onClick={() => this.props.onEdit(this.props.index, {}, "delete")}
                        >
                            <img src={DeleteIcon} width="15px" height="15px" alt="delete icon" />
                        </Button>}

                    </Card>

                </Card>

                {this.props.data.content_type === "note" &&
                    <NoteModal
                        show={this.props.data.modal}
                        handleClose={() => this.props.onEdit(this.props.index, { modal: false },)}
                        onSubmitNote={this.props.onEdit}
                        index={this.props.index}
                        data={this.props.data.contentData || this.props.data.content}
                        onSavingState={(percentage) => this.setState({ percentage: percentage })}
                    />}

                {this.props.data.content_type === "video" &&
                    <VideoModal
                        show={this.props.data.modal}
                        handleClose={() => this.props.onEdit(this.props.index, { modal: false },)}
                        onSubmitVideo={this.props.onEdit}
                        index={this.props.index}
                        data={this.props.data.contentData || this.props.data.content}
                        onUploadPercentageChange={(percentage) => this.setState({ percentage: percentage })}
                    />}
                {this.props.data.content_type === "quiz" &&
                    <QuizModal
                        show={this.props.data.modal}
                        handleClose={() => this.props.onEdit(this.props.index, { modal: false },)}
                        onSubmitQuiz={this.props.onEdit}
                        index={this.props.index}
                        data={this.props.data.contentData || this.props.data.content}
                    />}

            </li>
        )
    }
}

export default SyllabusContent