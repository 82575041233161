import APIClient from './httpClient';

export const overallOverviewAnalytics = async () => {
    /* get high level analytics data*/
    const api = new APIClient();
    const endpoint = '/overview/';

    const { data } = await api.AnalyticsApi.get(endpoint);
    return data;
}

export const topicsOverviewAnalytics = async () => {
    /* get high level analytics data*/
    const api = new APIClient();
    const endpoint = '/overview/topics/';

    const { data } = await api.AnalyticsApi.get(endpoint);
    return data;
}

export const quizzesOverviewAnalytics = async () => {
    /* get high level analytics data*/
    const api = new APIClient();
    const endpoint = '/overview/quizzes/';

    const { data } = await api.AnalyticsApi.get(endpoint);
    return data;
}

export const videosOverviewAnalytics = async () => {
    /* get high level analytics data*/
    const api = new APIClient();
    const endpoint = '/overview/videos/';

    const { data } = await api.AnalyticsApi.get(endpoint);
    return data;
}
