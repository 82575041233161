import styled from 'styled-components';

// Label with input embedded in it
export const InputLabel = styled.label`
    color: black;
    margin: 1em;
    font-weight: ${({ width }) => width || "auto"};
    display: ${({ displayType }) => displayType || "flex"};
    min-width: ${({ minWidth }) => minWidth || "max-content"};
    select{
        margin: 0 1em;
        padding: 0.5em;
        font-size: 1em;
    }

`