import APIClient from './httpClient';
import { Upload } from "tus-js-client";
import { getItemFromLocal } from '../functions/storage';

export const videoCreateUpdate = async (videoData) => {
    /* upload a video
     */
    const api = new APIClient()

    if (videoData.id) {
        const endpoint = `/update/${videoData.id}/`;
        const { data } = await api.VideoApi.put(endpoint, videoData);
        return data;

    } else {
        const endpoint = "/";
        const { data } = await api.VideoApi.post(endpoint, videoData);
        return data;

    }

}


export const uploadVideoToCloudFlare = async (videoData, onProgress, onSuccess, onAfterResponse, onError) => {


    /* DEBUG: if you notice weird CORS errors or an error like this 
      Video upload was interrupted Error: tus: invalid or missing offset value, originated from request (method: HEAD, url: https://video-one-time-upload-link.canamali.workers.dev/null, response code: 200, response text: , request id: n/a)
        at Upload._emitHttpError (upload.js:412)
        at upload.js:643 
    
        check the auth tokens. 
     */
    // Get the selected file from the input element
    var file = videoData.video_file

    // console.log(3, videoData.description, file.duration);

    var options = {
        endpoint: process.env.REACT_APP_CLOUDFLARE_ONE_TIME_VIDEO_UPLOAD_URL,
        chunkSize: 10 * 1024 * 1024, // Required a minimum chunk size of 5MB, here we use 10MB.
        metadata: {
            filename: file.name || "No title",
            name: videoData.title || "No title", // name that appears on the video. same as title
            title: videoData.title || "No title",
            filetype: file.type || "video/mp4",
            description: videoData.description,
            defaulttimestamppct: 0.5,
            user: getItemFromLocal("u_id"),
            thumbnailTimestampPct: 0.568427,
            // watermark: "$WATERMARKUID", // TODO: work on it later
            // maxDurationSeconds: file.duration,
            // "thumbnailTimestampPct": 0.568427,
            // "allowedOrigins": ["example.com"], //https://developers.cloudflare.com/stream/uploading-videos/direct-creator-uploads
        },
        // uploadSize: file.size,
        parallelUploads: 1,
        retryDelays: [0, 1000, 3000, 5000,],
        onError: onError,
        onProgress: onProgress,
        onSuccess: onSuccess,
        onAfterResponse: onAfterResponse

    };


    var upload = new Upload(file, options);
    // Check if there are any previous uploads to continue.
    await upload.findPreviousUploads().then(function (previousUploads) {
        // Found previous uploads so we select the first one. 
        if (previousUploads.length) {
            upload.resumeFromPreviousUpload(previousUploads[0])
        }

        // Start the upload
        upload.start()
    })
}
