import React, { Component } from 'react';
import styled from 'styled-components';
import { Card } from '../../components/card';
import { HighlightedText } from '../../components/highlightedText';
import { Input } from "../../components/input";
import InfoIcon from "../../assets/icons/info.svg";
import AddSectionIcon from '../../assets/icons/plus_rounded_wine.svg'
import { Button } from '../../components/uiComponents/button';
import UnPublishedIcon from '../../assets/icons/minus_red.svg'
import PublishedIcon from '../../assets/icons/checkmark_green.svg'
import SavedIcon from '../../assets/icons/checkmark-settings_green.svg'
import Question from '../../components/quiz/question';
import {
    getOnlyQuizQuestions,
    getQuizAndQuestions,
    quizCreateUpdate
} from "../../utils/api/quizApi";
import { createBrowserHistory } from 'history'
import BeatLoader from "react-spinners/BeatLoader";
import { convertDurationStringToMinutes, readableServerError } from '../../utils/utilFnx';
import SnackBar from 'my-react-snackbar';
import { Prompt } from 'react-router';

class QuizLab extends Component {

    constructor(props) {
        super();
        this.state = {
            questions: [],
            quiz: { published: false, duration: 10},
            saveChanges: false,
            loading: false,
            snackMessage: { type: null, message: null },

        }

        this.onRefreshPrompt = e => {
            // the method that will be used for both add and remove event
            e.preventDefault();
            e.returnValue = '';
        }
    }

    componentDidMount = () => {

        if (!process.env.REACT_APP_DEBUG) {
            window.addEventListener("beforeunload", this.onRefreshPrompt);
        }

        if (this.props.match.params.quizID) {
            if (this.props.location.state != null) {
                // console.log(this.props.location.state);
                this.setState({
                    quiz: {
                        ...this.props.location.state,
                        duration: convertDurationStringToMinutes(this.props.location.state.duration)
                    },
                })
                // without the 2 lines below, the state still 
                // refers to the old state of the application even after refresh
                const history = createBrowserHistory();
                history.replace();
                // get just questions
                this.setState({
                    loading: true
                })
                getOnlyQuizQuestions(this.props.match.params.quizID).then((data) => {
                    // console.log(data);
                    this.setState({
                        questions: data,
                        loading: false,
                    })
                }).catch(err => {
                    this.setState({
                        loading: false,
                        snackMessage: { type: "error", message: readableServerError(err) },
                    })
                })

            }
            else {
                // if is it not coming from state, fetch from db
                this.getQuizAndQuestionsData()

            }
        }
    }

    componentWillUnmount = () => {
        if (!process.env.REACT_APP_DEBUG) {
            window.removeEventListener("beforeunload", this.onRefreshPrompt)
        }
    }

    getQuizAndQuestionsData = () => {
        this.setState({
            loading: true
        })
        getQuizAndQuestions(this.props.match.params.quizID).then((data) => {
            this.setState({
                questions: data.questions,
                quiz: { ...data, duration: convertDurationStringToMinutes(data.duration) },
                loading: false,
            })
        }).catch(err => {
            this.setState({
                loading: false,
                snackMessage: { type: "error", message: readableServerError(err) },
            })
        })
    }

    onAddQuestion = (e) => {

        // when someone clicks on th Add module button, an empty map is added to the 
        // `syllabusSection` array in state. and then edit=True property is set for that item.
        this.setState({
            questions: [...this.state.questions,
            {
                number: this.state.questions.length,
                possible_answers: [],
                genre: "multiple-choice"
            }],
            saveChanges: true
        })

    }

    onEditQuestion = (id, data, command) => {
        let allQuestions = this.state.questions
        let question = allQuestions[id]
        if (command === "delete") {
            allQuestions.splice(id, 1);

        } else {

            question = { ...question, ...data }
            allQuestions[id] = question
        }
        this.setState({
            questions: allQuestions,
            saveChanges: true,
        })
    }
    onQuizChange = (e) => {
        this.setState({ quiz: { ...this.state.quiz, [e.target.name]: e.target.value }, saveChanges: true })
    }

    onSaveQuiz = (e) => {
        let quiz = this.state.quiz
        quiz.questions = this.state.questions
        quiz.duration = this.state.quiz.duration *60
        // console.log(this.state.questions);

        this.setState({
            loading: true,
            snackMessage: { type: null, message: null },
        })
        quizCreateUpdate(this.state.quiz).then((data) => {
            this.setState({
                quiz: {
                    ...this.state.quiz, ...data,
                    duration: convertDurationStringToMinutes(data.duration)
                },
                questions: data.questions || this.state.questions,
                snackMessage: { type: "success", message: "Quiz succesfully saved" },
                loading: false,
                saveChanges: false,

            })
        }).catch(err => {
            this.setState({
                snackMessage: { type: "error", message: readableServerError(err) },
                loading: false,
            })
        })
    }


    render() {
        // console.log(this.state.quiz.duration);

        return (
            <QuestionPageContainer>
                <SnackBar
                    open={this.state.snackMessage.message}
                    message={this.state.snackMessage.message}
                    type={this.state.snackMessage.type}
                    position="bottom-right"
                    timeout={2000}
                />
                <Prompt
                    when={this.state.saveChanges}
                    message="You are leaving this page with unsaved work. Are you sure you want to do this?"
                />

                {/* titile */}

                {/* Instructions to hte quiz */}

                {/* Listing the questions. Questions is a combination of multiple chose and typed choice */}
                <div className="questionDivContainer">
                    <Card
                        display="flex"
                        background="#F7F9FB"
                        space="space-between"
                        className="pageHeader"
                    >

                        <Input
                            placeholder="Title of quiz"
                            width="60%" margin="10px 35px"
                            textTransform="capitalize"
                            size="2em"
                            padding="5px 20px"
                            name="title"
                            value={this.state.quiz.title ?? ""}
                            onChange={this.onQuizChange}
                        />
                        <Button
                            background="rgba(232, 232, 232, 1)"
                            color="rgba(46, 49, 49, 1)"
                            padding="8px 15px"
                            height="3em"
                            boxShadow="3px 5px 6px #00000029"
                            margin="1em 0"
                            disabled={this.state.loading}
                            onClick={async () => {
                                await this.setState({ quiz: { ...this.state.quiz, published: !this.state.quiz.published } });
                                this.onSaveQuiz()
                            }}
                        >
                            {this.state.loading ?
                                <>
                                    <BeatLoader color="#39903F" loading={this.state.loading} size={10} />
                                    Loading...
                                </>
                                :
                                <>{this.state.quiz.published ? "Published" : "Publish"}
                                    <img
                                        src={this.state.quiz.published
                                            ? PublishedIcon
                                            : UnPublishedIcon}
                                        width="15px"
                                        height="15px"
                                        alt="published/unpublished"
                                    /></>}
                        </Button>
                        <Button
                            height="3em"
                            padding="5px 20px"
                            background={this.state.saveChanges ? "#39903F" : "rgba(232, 232, 232, 1)"}
                            color={this.state.saveChanges ? "#fff" : "rgba(46, 49, 49, 1)"}
                            margin="1em"
                            onClick={this.onSaveQuiz}
                            disabled={this.state.loading}
                        >
                            {
                                this.state.loading ?
                                    <>
                                        <BeatLoader color="#fff" loading={this.state.loading} size={10} />
                                        Loading...
                                    </>
                                    :

                                    <>  {this.state.saveChanges ? "Save Changes" : "Saved!"}
                                        {!this.state.saveChanges && <img
                                            src={SavedIcon}
                                            width="15px"
                                            height="15px"
                                            alt="savedIcon"
                                        />}
                                    </>
                            }

                        </Button>
                    </Card>


                    {
                        this.state.questions.map((question, i) =>
                            <Question
                                key={i}
                                index={i}
                                data={question}
                                length={this.state.questions.length}
                                onEdit={this.onEditQuestion}
                            />)
                    }


                    <Button
                        display="flex"
                        color="#923D41"
                        background="#fff"
                        border="2px dashed #923D41"
                        padding="0.5em"
                        margin="1em 2em"
                        space="space-between"
                        width="95%"
                        onClick={this.onAddQuestion}
                    >
                        <img src={AddSectionIcon} width="30px" alt="add section" />
                        <HighlightedText
                            size="16px"
                            weight="500"
                            color="#923D41"
                            lineHeight="1.5"
                            background="rgba(232, 232, 232, 1)"
                            padding="5px 10px"
                            radius="5px"
                            margin="0 0 0 1em"
                        >
                            ADD A QUESTION
                        </HighlightedText>

                    </Button>


                </div >




                <Card className="center-text pc-resultCard" width="15%" height="fit-content" margin="2em 1em 1em" padding="1em">
                    <HighlightedText margin="0.5em" size="18px">
                        Settings
                    </HighlightedText>
                    <HighlightedText color="grey" size="0.75em" margin="0.2em" display="flex" alignItems="center">
                        <img src={InfoIcon} width="10px" alt="info icon" />
                        Pass Mark:
                        <Input type="number"
                            name="pass_mark"
                            value={this.state.quiz.pass_mark ?? 0}
                            onChange={this.onQuizChange} />
                    </HighlightedText>
                    <HighlightedText color="grey" size="0.75em" margin="0.2em" display="flex" alignItems="center">
                        <img src={InfoIcon} width="10px" alt="info icon" />
                        Questions per quiz:
                        <Input
                            type="number"
                            name="questions_per_quiz"
                            value={this.state.quiz.questions_per_quiz ?? this.state.questions.length}
                            onChange={this.onQuizChange} />
                    </HighlightedText>
                    <HighlightedText color="grey" size="0.75em" margin="0.2em" display="flex" alignItems="center">
                        <img src={InfoIcon} width="10px" alt="info icon" />
                        Duration (mins):
                        <Input
                            type="number"
                            name="duration"
                            value={this.state.quiz.duration || ""}
                            onChange={this.onQuizChange} />
                    </HighlightedText>
                </Card>
            </QuestionPageContainer >

        )
    }
}

export default QuizLab;




const QuestionPageContainer = styled.div`

    margin: 1em;
    display: flex;

    .questionDivContainer{
        max-width: 80%;
        width:80%;
        margin: 1em;

        .pageHeader{
            position: sticky;
            top: 0;
        }
    }

    .pc-resultCard{
        position: sticky;
        top: 10%;
    }
    label{
        color: black;
        margin: 1em;
        select{
            margin: 0 1em;
            padding: 0.5em;
            font-size: 1em;
        }

    }
    button{
        img{
            margin: 0 5px;
        }
    }

    @media only screen and (max-width: 768px){
        flex-flow: wrap;
        margin: 5% 0;
        width: auto;

        .questionDivContainer{
            max-width: 100%;
            width:100%;
            margin: auto;
            padding: 0 5%;

            .pageHeader{
                margin: 1em auto;
                width: auto;
            }
            div{
                flex-flow: wrap;
                input{
                    width: 80%;
                    font-size: 1em;
                    padding: 10px;
                    margin: 10px 0;
                    margin: 5px 0 0;
                }
                select{
                    margin: 0.5em 1em;
                }
            }

            & > button{
                margin: auto;
            }
            
        }
        .pc-resultCard{
        position: relative;
        width: auto;
    }
        
    }

`