import styled from 'styled-components';



export const HighlightedText = styled.h1`
    font-size: ${({ size }) => size};
    font-weight: ${({ weight }) => weight || "bold"};
    font-style: ${({ fontStyle }) => fontStyle || "normal"};
    font-stretch: normal;
    line-height: ${({ lineHeight }) => lineHeight || "normal"};
    letter-spacing: normal;
    color: ${({ color }) => color || "#000000"};
    margin: ${({ margin }) => margin || "0px"};
    background-color: ${({ background }) => background};
    border-radius: ${({ radius }) => radius};
    padding: ${({ padding }) => padding};
    display: ${({ display }) => display};
    align-items: ${({ alignItems }) => alignItems};
    text-align: ${({ textAlign }) => textAlign};
    width: ${({ width }) => width};
    justify-content: ${({space}) => space};
    flex-flow: ${({ flexFlow }) => flexFlow};

    
    .center-text{
        text-align: center;
    }

    .right-text{
        float: right;
    }

    /*@media only screen and (max-width: 768px){
        font-size:medium
    }*/

`

export const HightlightedSpan = styled.span`
    font-size: ${({ size }) => size};
    font-weight: ${({ weight }) => weight || "bold"};
    font-style: ${({ fontStyle }) => fontStyle || "normal"};
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${({ color }) => color || "#000000"};
    margin: ${({ margin }) => margin || "0px"};
    background-color: ${({ background }) => background};
    padding: ${({ padding }) => padding};
    margin: ${({ margin }) => margin};
    border-radius: ${({ radius }) => radius};

    .center-text{
        text-align: center;
    }

    .right-text{
        float: right;
    }

`
