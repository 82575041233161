


export const setAuthToken = (token) => {
    localStorage.setItem("a_bearer", JSON.stringify(token));
}
export const getAuthToken = () => {
    return localStorage.getItem("a_bearer") ? JSON.parse(localStorage.getItem("a_bearer")) : null;
}
export const setInstitution = (token) => {
    localStorage.setItem("se_insti_ion", JSON.stringify(token));
}
export const getInstitution = () => {
    return localStorage.getItem("se_insti_ion") ? JSON.parse(localStorage.getItem("se_insti_ion")) : null;
}

export const setFirstName = (firstName) => {
    localStorage.setItem("f_name", JSON.stringify(firstName));
}
export const getFirstName = () => {
    return localStorage.getItem("f_name") ? JSON.parse(localStorage.getItem("f_name")) : null;
}


export const setLastName = (lastName) => {
    localStorage.setItem("l_name", JSON.stringify(lastName));
}
export const getLastName = () => {
    return localStorage.getItem("l_name") ? JSON.parse(localStorage.getItem("l_name")) : null;
}



export const setUserId = (userId) => {
    localStorage.setItem("u_id", JSON.stringify(userId));
}
export const getUserId = () => {
    return localStorage.getItem("u_id") ? JSON.parse(localStorage.getItem("u_id")) : null;
}



export const setAuthGroup = (group) => {
    localStorage.setItem("g_user", JSON.stringify(group));
}


export const getGroup = () => {
    return localStorage.getItem("g_user") ? JSON.parse(localStorage.getItem("g_user")) : null;
}

export const deleteAuthToken = () => {
    return localStorage.removeItem("a_bearer");
}


export const setCity = (city) => {
    localStorage.setItem("secitiyy", JSON.stringify(city));
}
export const getCity = () => {
    return localStorage.getItem("secitiyy") ? JSON.parse(localStorage.getItem("secitiyy")) : null;
}
export const setCountry = (country) => {
    localStorage.setItem("se_country", JSON.stringify(country));
}
export const getCountry = () => {
    return localStorage.getItem("se_country") ? JSON.parse(localStorage.getItem("se_country")) : null;
}
export const setImageUrl = (imageUrl) => {
    localStorage.setItem("image_pfrl", JSON.stringify(imageUrl));
}
export const getImageUrl = () => {
    return localStorage.getItem("image_pfrl") ? JSON.parse(localStorage.getItem("image_pfrl")) : null;
}
export const setEmail = (email) => {
    localStorage.setItem("em_u_id", JSON.stringify(email));
}
export const getEmail = () => {
    return localStorage.getItem("em_u_id") ? JSON.parse(localStorage.getItem("em_u_id")) : null;
}

export const deleteLocalStorageForLogout = () => {
    // remove token
    localStorage.removeItem("a_bearer");
    // remove sighted state. 
    localStorage.removeItem("s_sghtd");
}

export const setWindowLocationPathName = (pathname) => {
    localStorage.setItem("pth_nme", JSON.stringify(pathname));
}
export const getWindowLocationPathName = () => {
    return localStorage.getItem("pth_nme") ? JSON.parse(localStorage.getItem("pth_nme")) : null;
}

export const setInitialInstruction = (pathname) => {
    localStorage.setItem("init_instr", JSON.stringify(pathname));
}
export const getInitialInstruction = () => {
    return localStorage.getItem("init_instr") ? JSON.parse(localStorage.getItem("init_instr")) : null;
}
export const deleteInitialInstruction = () => {  
    localStorage.removeItem("init_instr");
}

export const getCounter = () => {
    return localStorage.getItem("cntr") ? JSON.parse(localStorage.getItem("cntr")) : 0;
}
export const setCounter = (pathname) => {
    localStorage.setItem("cntr", JSON.stringify(pathname));
}
