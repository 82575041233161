import styled from 'styled-components';

export const Card = styled.div`
display: ${({ display }) => display || "block"};
color: ${({ color }) => color || "#ffffff"};
background-color: ${({ background }) => background || "#ffffff"};
border: ${({border}) => border || 0};
padding: ${({ padding }) => padding || "0px 0px"};
margin: ${({ margin }) => margin || "0px 0px"};
border-radius: ${({radius}) => radius || "2px"};
width: ${({width}) => width || "auto"};
height: ${({height}) => height || "auto"};
box-shadow: ${({shadow}) => shadow || "none"};
justify-content: ${({space}) => space || "normal"};
align-items: ${({alignItems}) => alignItems || "normal"};

flex-flow: ${({flexFlow}) => flexFlow};
/* TODO: use display flex instead of this class*/
    &.flexInCard{
        display: flex;
    }

    &.spaceAround{
        justify-content: space-around;
    }
    
    /* to use the hover, make sure you already have a border property set by default */
    &:hover{
        border-color: ${({ hoverBorderColor }) => hoverBorderColor || "#a93b3f"};
    }
    
`;
