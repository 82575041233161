
import React from 'react';
import { Card } from "../../components/card";
import { HighlightedText } from '../../components/highlightedText';


class UnavailableAnalytics extends React.Component{

    render =()=>{
        return <Card 
        background="rgba(232,232,232,0.6)" 
        width="max-content" 
        padding="15px" 
        textAlign="center"
        display="block"
        margin="2em auto"
        >
            <HighlightedText>
                No {this.props.content}
            </HighlightedText>
        </Card>
    }
}

export default UnavailableAnalytics