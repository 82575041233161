
import React from 'react';
import { Card } from './card'
import styled from 'styled-components';
import CourseImg from '../assets/images/defaultTopic.jpg'
import { HighlightedText } from './highlightedText';
import { enrollment, timeSince, durationReadable } from "../utils/utilFnx";
import { Link } from 'react-router-dom';

class CourseCard extends React.Component {

    render() {
        return (
            <CourseCardContainer to={{
                pathname: `/topic/edit/${this.props.data.id}`,
                state: this.props.data   
            }}>

                {/* Bigger card */}
                {/* Picture card at the top */}
                {/* Author picuture or logo card */}
                {/* Name of the topic */}
                {/* Number of student who have taken  */}
                {/* shen the topic was posten */}
                <img src={this.props.data.thumbnail ?? CourseImg} alt="topic" height="150px" width="100%" />
                <Card display="flex" margin="0.5em 0.5em 0">

                    <Card margin="0 0.5em" width="100%">
                        <Card display="flex" space="space-between">
                            <HighlightedText color="#923D41" size="16px" weight="600" margin="0em 0em">
                                {this.props.data.title}
                            </HighlightedText>

                            {this.props.data.published ?
                                <HighlightedText
                                    color="rgba(1, 152, 117, 1)"
                                    background="rgba(0, 177, 106, 0.3)"
                                    size="12px"
                                    weight="700"
                                    margin="0em 0em"
                                    padding="5px"
                                    radius="2px"
                                    width="min-content"
                                >
                                    Published
                                </HighlightedText>
                                :
                                <HighlightedText
                                    color="rgba(207, 0, 15, 1)"
                                    background="rgba(224, 130, 131, 0.3)"
                                    size="12px"
                                    weight="700"
                                    margin="0em 0em"
                                    padding="5px"
                                    radius="2px"
                                    width="min-content"
                                >
                                    UnPublished
                            </HighlightedText>
                            }
                        </Card>
                        <Card>

                            <HighlightedText color="grey" size="15px" weight="500" margin="0.2em 0.2em">
                                Complete in: {durationReadable(this.props.data.duration)}
                            </HighlightedText>
                        </Card>
                        <Card>

                            <HighlightedText color="grey" size="15px" weight="500" margin="0.2em 0.2em">
                                Eligibility: {this.props.data.level}
                            </HighlightedText>
                        </Card>

                            <HighlightedText 
                            color="rgba(142,68,173,1)" 
                            background="rgba(190,144,212,0.5)" 
                            size="15px" 
                            weight="500" 
                            margin="0.2em 0.2em"
                            padding="5px"
                            width="max-content"
                            >
                                Payment Plan: {this.props.data.payment_plan}
                            </HighlightedText>
                        
                        <Card display="flex">
                            <HighlightedText color="grey" size="13px" weight="normal" margin="0.2em 0.2em">{enrollment(this.props.data.enrollment)}</HighlightedText>
                            <HighlightedText color="grey" size="13px" weight="normal" margin="0.2em 0.2em 0">| Updated {timeSince(new Date(this.props.data.updated_datetime))}</HighlightedText>
                        </Card>
                    </Card>
                </Card>


            </CourseCardContainer>
        )
    }

}

export default CourseCard



const CourseCardContainer = styled(Link)`
    
    padding: 0 0em 0.5em;
    background-color: #fff;
    min-width: 22%;
    border-radius: 0 0 5px 5px;
    margin: 1em;
    border: 1px solid transparent;
    cursor: pointer;
    width: fit-content;
    height: min-content;

    &:hover{
        box-shadow: 0px 12px 30px #923D411A;
    } 

    @media only screen and (max-width: 400px){
        width: 95%;
        margin: 1em 0;
    } 

    @media only screen and (max-width: 620px) and (min-width: 400px){
        img{
            width: 100%;
            height: 80%;
        }
    }

`
