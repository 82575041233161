import styled from "styled-components";


export const LogoImg = styled.img`
    border-radius: ${({ radius }) => radius};
    object-fit: ${({ fit }) => fit || "contain"};
    display: block;
    background-color: ${({ background }) => background};
    margin: ${({ margin }) => margin};
`;
